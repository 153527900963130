import React from 'react';

import pinhead from '../../images/pinhead.png';

function PinheadIcon() {
  let marker = pinhead;
  let iconWidth = 20;
  let iconHeight = 20;

  return <img className="pushpin" src={marker} width={iconWidth} height={iconHeight} />;
}

export default PinheadIcon;
