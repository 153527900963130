import React, { Component } from 'react';

export default class CinemaRecordSummaryAggregation extends Component {
  render() {
    const { bucketName, bucketTitle, aggregation } = this.props;

    let aggregationRows = aggregation.map((bucket) => {
      const options = { year: 'numeric' };
      const fullDate = new Date(bucket.key_as_string);
      const displayDate = fullDate.toLocaleDateString('en-GB', options);
      let aggregationRow = [];

      if (bucket.venue.buckets.length > 0) {
        if (bucket.venue.buckets[0][bucketName].buckets.length > 0) {
          let categoryRows = bucket.venue.buckets[0][bucketName].buckets.map((category) => {
            return (
              <tr key={category.key}>
                <td>{category.key}</td>
                <td>{category.doc_count}</td>
                <td>{category.unique_title.value}</td>
              </tr>
            );
          });
          aggregationRow.push(
            <div key={displayDate} className="col">
              <h4>{displayDate}</h4>
              <table className="table  table-bordered">
                <thead>
                  <tr>
                    <th>{bucketTitle}</th>
                    <th>Screenings</th>
                    <th>Unique Titles</th>
                  </tr>
                </thead>
                <tbody>{categoryRows}</tbody>
              </table>
            </div>,
          );
        }
      }
      return aggregationRow;
    });

    const maxLength = aggregationRows.reduce((max, curr) => {
      if (max > curr.length) return max;
      else return curr;
    }, 0);

    if (maxLength.length > 0) {
      return (
        <>
          <h3>{bucketTitle}</h3>
          <div className="row"> {aggregationRows}</div>
        </>
      );
    }
    return null;
  }
}
