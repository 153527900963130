import ResultList from './results/ResultList';

const FilmList = () => {
  const entity = 'film';
  const entityName = 'Films';
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - ${entityName}`;

  return <ResultList entity={entity} title={title} entityName={entityName} />;
};

export default FilmList;
