import { Popup, Tooltip } from 'react-leaflet';
import { Link } from 'react-router-dom';

function CinemaPopup({ cinema, header = null }) {
  return (
    <>
      <Tooltip>{cinema.name}</Tooltip>
      <Popup minWidth={500} maxWidth={500} maxHeight={300}>
        <div className="container">
          <div className="row">
            <h1 className="col" style={{ fontSize: '20px' }}>
              {cinema.name}
            </h1>
          </div>
          {header}
          <div className="row">
            <div className="col">
              {cinema.street !== '' && (
                <div>
                  <strong>Address: </strong> {cinema.street}
                  <br />
                </div>
              )}
              {cinema.rank !== '' && (
                <div>
                  <strong>Rank: </strong> {cinema.rank}
                  <br />
                </div>
              )}
              {cinema.seatingRange !== '' && (
                <div>
                  <strong>Seating Range: </strong> {cinema.seatingRange}
                  <br />
                </div>
              )}

              {cinema.opening !== '' && (
                <div>
                  <strong>Opening date: </strong> {cinema.opening}
                  <br />
                </div>
              )}

              {cinema.closing !== '' && (
                <div>
                  <strong>Closing date: </strong> {cinema.closing}
                  <br />
                </div>
              )}

              {cinema.seats !== '' && (
                <div>
                  <strong>Number of seats: </strong>
                  {cinema.seats}
                  <br />
                </div>
              )}

              <br />
              <Link to={`/cinema/${cinema.id}`}>more information</Link>
            </div>
            <div className="col cinema-popup-images">
              <div className="d-flex flex-row flex-wrap">
                {cinema.images.map(function (image, index) {
                  return (
                    <div key={index} className="col-6">
                      <Link key={image.id} to={`/cinema/${cinema.id}/image/${image.id}`}>
                        <img
                          src={image.srcThumb}
                          width={100}
                          className={`img-fluid  m-1 img-space img-number-${index}`}
                          alt="cinema"
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row popup-buttons">
            <Link
              to={`/interview?cinemas[]=${cinema.id}#nav-gallery`}
              data-toggle="tooltip"
              data-placement="top"
              title="Watch interviews"
              className="col btn btn-space btn-outline-secondary"
            >
              WATCH
              <br /> the interviews
            </Link>
            <Link
              to={{
                pathname: '/screening',
                search: `?venue%5B%5D=${cinema.name}&cityDisplayName%5B%5D=${cinema.city}`,
              }}
              // type="button"
              data-toggle="tooltip"
              data-placement="top"
              title={'See the screenings shown in ' + cinema.name}
              className="col btn btn-space btn-outline-secondary"
            >
              SEE
              <br /> the screenings
            </Link>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default CinemaPopup;
