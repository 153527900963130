import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import DownloadData from '../results/show/DownloadData';

const ScreeningDetail = ({ screenings, activeScreening, cinema, date }) => {
  const location = useLocation();
  const dateObject = new Date(date.iso);
  const formattedDate = `${dateObject.toLocaleDateString('en-GB', {
    weekday: 'long',
  })}, ${dateObject.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}`;
  return (
    <>
      {/* <div className="row">
        <div className="col-md-12">
          {' '}
          Screenings Summaries by
          <ul className="list-unstlyed list-inline">
            <li className="list-inline-item">City</li>
            <li className="list-inline-item">Year</li>
          </ul>
        </div>
      </div> */}
      <div id="screening-details">
        <div className="row">
          <div className="col-md-12">
            <h1>
              <Link to={`/film/${activeScreening.overview.filmId.value}`}>
                {activeScreening.overview.title.value}
              </Link>
              ,{' '}
              <small>
                <Link to={`/cinema/${cinema.id}`}>{cinema.name}</Link>,{' '}
                <Link to={`/screening/?filter_cityDisplayName_raw[]=${cinema.city}`}>
                  {cinema.city}
                </Link>{' '}
                –{' '}
                <Link to={`/screening?dateFrom=${date.iso}&dateTo=${date.iso}`}>
                  {formattedDate}
                </Link>
              </small>
            </h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            {/* {activeScreening.summary.map((summary, index) => (
            <div key={index}>
              <strong>{summary.name}</strong> {summary.value}
            </div>
          ))} */}
            <h3>Censorship Information</h3>
            {activeScreening.censorship.length > 0 ? (
              <>
                {activeScreening.censorship.map((summary, index) => (
                  <div key={index}>
                    <label className="fw-bold">{summary.name}</label> {summary.value}
                  </div>
                ))}
              </>
            ) : (
              <label>No censorship information recorded.</label>
            )}
          </div>
          <div className="col-md-6">
            <h2>
              All screenings on this day at <Link to={`/cinema/${cinema.id}`}>{cinema.name}</Link>:
            </h2>
            <ul className="list-unstyled">
              {screenings.map((screening) => (
                <li key={screening.id}>
                  <Link to={`/screening/${screening.id}${location.search}`}>Screening</Link>:{' '}
                  <Link to={`/film/${screening.overview.filmId.value}`}>
                    <strong>{screening.overview.title.value}</strong>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            {/* {activeScreening.summary.map((summary, index) => (
            <div key={index}>
              <strong>{summary.name}</strong> {summary.value}
            </div>
          ))} */}
            <h4>Film Information</h4>
            {activeScreening.overview.title.value !== '' && (
              <div>
                <label className="fw-bold">{activeScreening.overview.title.name}</label>{' '}
                {activeScreening.overview.title.value}
              </div>
            )}
            {activeScreening.overview.titleVariation1.value !== '' && (
              <div>
                <label className="fw-bold">{activeScreening.overview.titleVariation1.name}</label>{' '}
                {activeScreening.overview.titleVariation1.value}
              </div>
            )}
            {activeScreening.overview.titleVariation2.value !== '' && (
              <div>
                <label className="fw-bold">{activeScreening.overview.titleVariation2.name}</label>{' '}
                {activeScreening.overview.titleVariation2.value}
              </div>
            )}
            {activeScreening.overview.year.value !== '' && (
              <div>
                <label className="fw-bold">{activeScreening.overview.year.name}</label>{' '}
                {activeScreening.overview.year.value}
              </div>
            )}
            {activeScreening.overview.imdb.value !== '' && (
              <div>
                <label className="fw-bold">{activeScreening.overview.imdb.name}</label>{' '}
                {activeScreening.overview.imdb.value && (
                  <a
                    className="ms-0 fs-6"
                    href={`https://www.imdb.com/title/${activeScreening.overview.imdb.value}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {activeScreening.overview.imdb.value}{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                )}
              </div>
            )}

            {activeScreening.overview.filmCountry.value.length > 0 && (
              <div>
                <label className="fw-bold">{activeScreening.overview.filmCountry.name}</label>{' '}
                {activeScreening.overview.filmCountry.value.join(', ')}
              </div>
            )}
            {activeScreening.overview.filmGenre.value.length > 0 && (
              <div>
                <label className="fw-bold">{activeScreening.overview.filmGenre.name}</label>{' '}
                {activeScreening.overview.filmGenre.value.join(', ')}
              </div>
            )}

            {/* {activeScreening.overview.title.value !='' && ()} */}

            <div>
              <label className="fw-bold">Actors</label>{' '}
              {[
                ...new Set(
                  activeScreening.overview.people.value
                    .filter((person) => person.type === 'Actor')
                    .sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    })
                    .map((person) => person.name),
                ),
              ].join(', ')}
            </div>
            <div>
              <label className="fw-bold">Directors</label>{' '}
              {[
                ...new Set(
                  activeScreening.overview.people.value
                    .filter((person) => person.type === 'Director')
                    .sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    })
                    .map((person) => person.name),
                ),
              ].join(', ')}
            </div>
            <div>
              <label className="fw-bold">Production Companies</label>{' '}
              {[
                ...new Set(
                  activeScreening.overview.companies.value
                    .filter((company) => company.type === 'Production')
                    .sort(function (a, b) {
                      return b.sort - a.sort;
                    })
                    .map((company) => company.name),
                ),
              ].join(', ')}
            </div>
            <div>
              <label className="fw-bold">Distribution Companies</label>{' '}
              {[
                ...new Set(
                  activeScreening.overview.companies.value
                    .filter((company) => company.type === 'Distribution')
                    .sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    })
                    .map((company) => company.name),
                ),
              ].join(', ')}
            </div>
          </div>
          <div className="col-md-6">
            <h4>Cinema Information</h4>
            <div>
              <label className="fw-bold">Name</label> {cinema.name}
            </div>
            <div>
              <label className="fw-bold">Street</label> {cinema.street}
            </div>
            {cinema.district !== '' && (
              <div>
                <label className="fw-bold">District</label> {cinema.district}
              </div>
            )}
            <div>
              <label className="fw-bold">City</label> {cinema.cityDisplayName}
            </div>
            <div>
              <label className="fw-bold">County</label> {cinema.country}
            </div>
            <div>
              <label className="fw-bold">Location</label> {cinema.location}
            </div>
            <div>
              <label className="fw-bold">Type</label> {cinema.type}
            </div>
            <div>
              <label className="fw-bold">Rank</label> {cinema.rank}
            </div>
            <div>
              <label className="fw-bold">Geodata</label> {cinema.geodata}
            </div>
          </div>
        </div>
      </div>
      <DownloadData
        entity={'screening'}
        entityName="Screening"
        id={activeScreening.id}
        screeningField={null}
        screeningValue={activeScreening.id}
      />
    </>
  );
};
export default ScreeningDetail;
