import React from 'react';

import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import { COLORPAIRS } from '../../utils/colors.jsx';

const CinemaTimeline = ({ metrics, animate, size = 150 }) => {
  const baseRadius = size / 10;
  const renderTooltip = ({ active, payload }) => {
    if (active) {
      const currentSlice = payload && payload.length ? payload[0].payload : null;
      const message =
        currentSlice.payload.name === 'No Screenings'
          ? 'No Screenings'
          : `${currentSlice.payload.label} screening: ${currentSlice.payload.value}`;
      return (
        <div
          className=""
          style={{
            backgroundColor: 'white',
            border: '1px black solid',
            borderRadius: '20px',
            padding: '5px',
          }}
        >
          <span style={{ fontSize: '10px', color: currentSlice.fill, whiteSpace: 'nowrap' }}>
            {message}
          </span>
        </div>
      );
    }
    return null;
  };
  return (
    <PieChart width={size} height={size}>
      {metrics.map((year, yearIndex) => {
        const ir = baseRadius * (yearIndex + 1);
        const or = ir + baseRadius;

        return (
          <Pie
            key={year.name}
            nameKey={'label'}
            isAnimationActive={animate}
            data={year.data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={ir}
            outerRadius={or}
          >
            {year.data.map((category, categoryIndex) => {
              const color =
                category.name === 'No Screenings'
                  ? '#999999'
                  : COLORPAIRS[yearIndex][categoryIndex];
              return <Cell key={`cell-${categoryIndex}`} fill={color} />;
            })}
          </Pie>
        );
      })}
      <Tooltip content={renderTooltip} />
    </PieChart>
  );
};

CinemaTimeline.propTypes = {
  metrics: PropTypes.array,
  animate: PropTypes.bool,
  size: PropTypes.number,
};

export default CinemaTimeline;
