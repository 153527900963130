import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import useFilters from '../../hooks/useFilters';

const AggregationBucket = ({ aggregationName, value, reverse }) => {
  const location = useLocation();
  const [searchParams, remove] = useFilters(aggregationName, value.key);

  const name = value.name ? value.name : value.key;
  return (
    <li className="">
      <Link
        to={{
          pathname: location.pathname,
          search: searchParams.toString(),
          hash: location.hash,
        }}
        className="map-filter-link"
      >
        <input
          className="form-check-input"
          checked={remove}
          type="checkbox"
          readOnly={true}
          disabled={true}
        />
        <span className="ms-2">{name}</span>
        {value.doc_count > 0 && (
          <span className="ms-1">({reverse ? value.term_count : value.doc_count})</span>
        )}
      </Link>
    </li>
  );
};

AggregationBucket.propTypes = {
  aggregationName: PropTypes.string,
  value: PropTypes.object,
  reverse: PropTypes.bool,
};

export default AggregationBucket;
