import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'bootstrap';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
const InterviewMetaData = ({ value, label, help }) => {
  if (!value || value === '') return null;

  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });

  const divProps = {};

  if (help) {
    divProps['data-bs-toggle'] = 'tooltip';
    divProps['data-bs-placement'] = 'top';
    divProps['data-bs-html'] = true;
    divProps['data-bs-title'] = ReactDOMServer.renderToString(help);
    divProps['icon'] = faCircleQuestion;
  }
  return (
    <div className="d-inline pe-2">
      <strong>
        {label}{' '}
        {help && (
          <>
            <FontAwesomeIcon {...divProps} />
          </>
        )}
        :{' '}
      </strong>
      <span>{value}</span>
    </div>
  );
};

InterviewMetaData.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export default InterviewMetaData;
