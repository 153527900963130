import PriceProxyTable from './PriceProxyTable';
import useAxios from '../../hooks/useAxios';
import Error from '../../layout/Error';
import Loading from '../../layout/Loading';

const PriceProxyRecord = ({ city }) => {
  const apiPath = `${process.env.REACT_APP_API_URL}/city/${city}/20/priceproxy`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <>
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <>
                {data.priceProxyData && (
                  <PriceProxyTable
                    n={20}
                    ppYears={data.priceProxyData.years}
                    ppFilms={data.priceProxyData.films}
                    ppCinemas={data.priceProxyData.venues}
                    ppCinemaCounts={data.priceProxyData.venueCounts}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PriceProxyRecord;
