import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, Link } from 'react-router-dom';

import { codeColor } from '../../utils/colors';

const CodeTags = ({ codes, isLink = true }) => {
  const location = useLocation();

  return (
    <ul className="list-inline text-end ms-1 mb-1">
      {codes.map((code) => {
        const color = codeColor(code);
        let query = queryString.parse(location.search, {
          arrayFormat: 'bracket',
        });
        let remove = false;

        if (Object.hasOwnProperty.bind(query)('codes_name')) {
          if (query['codes_name'].includes(code.name)) {
            remove = true;
            query['codes_name'] = query['codes_name'].filter((item) => item !== code.name);
          } else {
            query['codes_name'].push(code.name);
          }
        } else {
          query.codes_name = [code.name];
        }
        const search = queryString.stringify(query, {
          arrayFormat: 'bracket',
        });

        return (
          <li key={code.id} className="list-inline-item mb-1 ms-1 me-0">
            {isLink ? (
              <Link
                className="badge text-wrap"
                style={{
                  color: 'white',
                  borderColor: color,
                  backgroundColor: color,
                }}
                title={code.fullName}
                to={{
                  pathname: location.pathname,
                  search: search,
                  hash: location.hash,
                }}
              >
                {code.name} {remove && <span>[x]</span>}
              </Link>
            ) : (
              <div
                className="badge text-wrap"
                style={{
                  color: 'white',
                  borderColor: color,
                  backgroundColor: color,
                }}
                title={code.fullName}
              >
                {code.name}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

CodeTags.propTypes = {
  codes: PropTypes.array,
};

export default CodeTags;
