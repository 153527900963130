import enGB from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

// import FormFilter from "../../form/FormFilter";

import useForm from '../hooks/useForm';
import useFormOptions from '../hooks/useFormOptions';
import Loading from '../layout/Loading';

registerLocale('en-GB', enGB);

const SearchHeader = ({ entity }) => {
  const [
    values,
    handleChange,
    handleDateChange,
    handleSelectChange,
    handleSubmit,
    handleReset,
    initialDates,
  ] = useForm();
  const apiPath = `${process.env.REACT_APP_API_URL}/form/v2/${entity}`;
  const { options, isLoading, error } = useFormOptions(apiPath, [], [apiPath]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <div className="input-group mb-3">
                <input
                  type="search"
                  className="form-control"
                  name="keyword"
                  id="field_keyword"
                  placeholder="keyword…"
                  value={values['keyword'] || ''}
                  onChange={handleChange}
                />
                <button className="btn btn-secondary" type="submit">
                  Search
                </button>
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#search-more-options"
                  aria-expanded="false"
                  aria-controls="search-more-options"
                >
                  More options
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="collapse" id="search-more-options">
          <div className="row">
            <div className="col-12">
              <div className="d-flex mb-3">
                <strong className="me-5 ">Date</strong>
                <label htmlFor="dateFrom" className="d-block me-3 ">
                  From:
                </label>
                <div className="me-5">
                  <DatePicker
                    selected={values['dateFrom']}
                    onChange={(date) => handleDateChange({ name: 'dateFrom', value: date })}
                    selectsStart
                    startDate={values['dateFrom']}
                    endDate={values['dateTo']}
                    name="dateFrom"
                    minDate={initialDates.dateFrom}
                    maxDate={initialDates.dateTo}
                    dateFormat="PP"
                    locale="en-GB"
                    showYearDropdown
                    value={values['dateFrom']}
                  />
                </div>
                <label htmlFor="dateFrom" className="d-block me-3 ">
                  To:
                </label>

                <div>
                  <DatePicker
                    selected={values['dateTo']}
                    onChange={(date) => handleDateChange({ name: 'dateTo', value: date })}
                    selectsEnd
                    startDate={values['dateFrom']}
                    endDate={values['dateTo']}
                    minDate={values['dateFrom']}
                    maxDate={initialDates.dateTo}
                    dateFormat="PP"
                    locale="en-GB"
                    showYearDropdown
                    name="dateTo"
                    value={values['dateTo']}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {options.map((option) => (
              <div key={option.key} className="col-md-6 col-xxl-6 mb-3">
                <div className="d-flex">
                  <div className="">
                    <strong>{option.name}</strong>
                  </div>
                  {option.exclusive && (
                    <div className="ms-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={option.exclusive.key}
                          value={values[option.exclusive.key]}
                          onChange={handleChange}
                          defaultChecked={values[option.exclusive.key]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={option.key}
                          title={option.exclusive.name}
                        >
                          exclusive?
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <Select
                  options={option.options}
                  isMulti
                  name={option.key}
                  value={values[option.key]}
                  onChange={(value) => handleSelectChange({ name: option.key, values: value })}
                />
              </div>
            ))}
          </div>
          <div className="row mb-3">
            <div className="  ">
              <button className="btn btn-secondary" type="submit">
                Search
              </button>
              <button className="btn btn-primary ms-2" type="reset">
                Clear
              </button>
            </div>
          </div>
        </div>

        {/* <div className="row gx-2">{filters}</div> */}
      </form>
    </>
  );
};
export default SearchHeader;
