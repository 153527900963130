import { useParams } from 'react-router-dom';

import VisualisationScreeningComparisonDetails from './visualisation/VisualisationScreeningComparisonDetails';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const VisualisationScreeningComparison = () => {
  const { city1, city2, type, value1, value2 } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Visualisation Screening Comparison`;

  const apiPath = `${process.env.REACT_APP_API_URL}/overview/screening/timeline/${city1}/${city2}/${type}/${value1}/${value2}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <VisualisationScreeningComparisonDetails
                chartData={data.chart.data}
                categories={data.categories}
                cities={data.cities}
                chartTitle={data.chart.title}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default VisualisationScreeningComparison;
