import { useSearchParams } from 'react-router-dom';

import useAxios from './useAxios';

const useInterviewSearch = (apiPath) => {
  const [searchParams] = useSearchParams();

  const exclude = [];
  exclude.forEach((key) => {
    searchParams.delete(key);
  });
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath, searchParams.toString()]);
  return {
    searchResults: data.searchResults
      ? data.searchResults
      : {
          highlights: [],
          interviewLineHits: [],
          transcriptLineHits: [],
          keyword: '',
          aggregations: [],
          codes: [],
        },
    searchIsLoading: isLoading,
    searchError: error,
  };
};

export default useInterviewSearch;
