import React from 'react';

import BaseContainer from './BaseContainer';
import TimelineMarkers from '../Marker/TimelineMarkers';

const TimelineContainer = ({ title, options }) => {
  return (
    <BaseContainer
      showAggregations={false}
      showCityNav={false}
      showMarkerNav={false}
      mapHeight={'400px'}
      markers={<TimelineMarkers title={title} options={options} />}
    />
  );
};

export default TimelineContainer;
