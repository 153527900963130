import React from 'react';

import { GeoJSON } from 'react-leaflet';

import brnoGeoJson from '../../geoJson/brno.json';

function CityLocationLayer() {
  return (
    <GeoJSON
      data={brnoGeoJson}
      style={(feature) => {
        switch (feature.properties.cat) {
          case 0:
            return { color: '#fde725', weight: 1, opacity: 0.8, fillOpacity: 0.2 };
          case 1:
            return { color: '#440154', weight: 1, opacity: 0.8, fillOpacity: 0.2 };
          case 2:
            return { color: '#20908d', weight: 1, opacity: 0.8, fillOpacity: 0.2 };
        }
      }}
    />
  );
}

export default CityLocationLayer;
