import { Collapse } from 'bootstrap';
import { NavLink } from 'react-router-dom';

import SearchAll from './SearchAll';
import ecaLogo from '../images/eca-logo.svg';

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-light bg-white navbar-expand-md my-0 pt-2 pb-1 ps-0 pe-3 align-items-center justify-content-md-between justify-content-around">
        <div className="mx-2 d-flex align-items-center">
          <NavLink to="/" className="">
            <img
              src={ecaLogo}
              height="50"
              alt="European Cinema Audiences Research"
              className="py-1 px-2"
            />
          </NavLink>
        </div>
        <div className="navbar-expand-sm">
          <div
            className="navbar-collapse collapse  mt-2 mt-md-0 eca-navbar-responsive"
            id="eca-navbar-search"
          >
            <SearchAll />
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".eca-navbar-responsive"
          aria-controls="ecaNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>
      <nav className="navbar navbar-light bg-white navbar-expand-md pt-1 pb-3 ps-3 pe-3 d-flex align-items-center">
        <div
          className="navbar-collapse collapse flex-wrap justify-content-between eca-navbar-responsive"
          id="eca-navbar"
        >
          <ul className="navbar-nav bd-navbar-nav eca-menu mt-0 py-md-0 ">
            <li className="dropdown">
              <a
                className="nav-link eca-about"
                href="https://www.europeancinemaaudiences.org/blog/about/"
              >
                About
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle eca-about-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/about/"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/category/team/"
                  >
                    Team
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/category/partner/"
                  >
                    Partners
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/outputs/"
                  >
                    Publications
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/category/future/"
                  >
                    Events
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/galleries/"
                  >
                    Galleries
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/category/blog/"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://www.europeancinemaaudiences.org/blog/contact-us/"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/city" className="nav-link">
                Cities
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/cinema" className="nav-link">
                Cinemas
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/film" className="nav-link">
                Films
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/screening" className="nav-link">
                Screenings
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/person" className="nav-link">
                People
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/company" className="nav-link">
                Companies
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/interview" className="nav-link">
                Interviews
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav  bd-navbar-nav eca-menu">
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/visualisation" className="nav-link eca-visualisation">
                Visualisations
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/map" className="nav-link">
                Maps
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/top/film/all/1y/20/all" className="nav-link">
                Top Films
              </NavLink>
            </li>
            <li className="nav-item col-6 col-md-auto">
              <NavLink to="/top/cinema/all/1y/20" className="nav-link">
                Top Cinemas
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
