import { useParams } from 'react-router-dom';

import TimelineChartMarker from './TimelineChartMarker';
import useAxios from '../../hooks/useAxios';
// import Routing from '../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
// import CinemaMarkerClusterGroup from '../MarkerClusterGroup/CinemaMarkerClusterGroup';
// import axios from 'axios';

// const routes = require('../../../../../assets/js/fos_js_routes.json');
// Routing.setRoutingData(routes);

function TimelineMarkers({ title, options }) {
  const { type, value1, value2 } = useParams();

  const apiPath = `${process.env.REACT_APP_API_URL}/overview/cinema/timeline/${type}/${value1}/${value2}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  // useEffect(() => {
  //   let mounted = true;

  //   const route = 'api_overview_cinema_timeline';
  //   const fetchURL = Routing.generate(
  //     route,
  //     { type: options.type, value1: options.value1, value2: options.value2 },
  //     true,
  //   );

  //   axios.get(fetchURL).then(res => {
  //     if (mounted) {
  //       const data = res.data.chart.data;
  //       setData(data);
  //     }
  //   });

  //   return function cleanup() {
  //     mounted = false;
  //   };
  // }, [options]);

  const markers = isLoading
    ? null
    : data.chart.data
        .filter((datum) => {
          return (
            datum.hasOwnProperty('cinema') &&
            datum.cinema.hasOwnProperty('address') &&
            datum.cinema.address.hasOwnProperty('position')
          );
        })
        .map((datum) => {
          return <TimelineChartMarker key={datum.id} title={title} data={datum} />;
        });

  return markers;
}

export default TimelineMarkers;
