import { Link, useSearchParams } from 'react-router-dom';
{
  /* <FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" /> */
}
{
  /* <FontAwesomeIcon icon="fa-solid fa-arrow-down-short-wide" /> */
}
const TableHeading = ({ columns, showImage = false, sort = true }) => {
  const [searchParams] = useSearchParams();

  const headings = columns.map((column) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const direction = searchParams.get('direction') || 'asc';
    const active = searchParams.get('sort') === column.key;
    const newDirection = active && direction === 'asc' ? 'desc' : 'asc';
    const iconClass =
      newDirection === 'asc' ? 'fa fa-arrow-down-wide-short' : 'fa fa-arrow-down-short-wide';
    newSearchParams.set('sort', column.key);
    newSearchParams.set('direction', newDirection);

    return (
      <th key={column.key}>
        {sort ? (
          <Link
            className={`${active ? 'active' : ''}`}
            to={{
              search: newSearchParams.toString(),
            }}
          >
            {column.name} {iconClass !== '' && <i className={`fas ${iconClass}`}></i>}
          </Link>
        ) : (
          <>{column.name} </>
        )}
      </th>
    );
  });
  return (
    <thead>
      <tr>
        {showImage && <th></th>}
        {headings}
      </tr>
    </thead>
  );
};
export default TableHeading;
