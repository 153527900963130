import { useContext } from 'react';

// import { timeFormat } from 'd3-time-format';

import { Context } from '../../Store';

const style = {
  padding: 6,
  backgroundColor: '#fff',
  border: '1px solid #ccc',
};

const CustomTooltip = ({ active, payload, filmCity }) => {
  const [state, dispatch] = useContext(Context);

  // const formatTime = timeFormat('%B %Y');

  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    const formattedLabel = state.navigationCities.find((c) => c.key === currData.label);

    return (
      <div className="area-chart-tooltip" style={style}>
        <p>
          {filmCity + ' : '}
          <em>{currData ? (formattedLabel ? formattedLabel.name : currData.label) : ' -- '}</em>
        </p>
        {filmCity !== 'Cinema' && (
          <p>
            {'Popstat : '}
            <em>{currData ? currData.popstat : ' -- '}</em>
          </p>
        )}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
