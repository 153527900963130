import ResultList from './results/ResultList';

const CityList = () => {
  const entity = 'city';
  const entityName = 'Cities';
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - ${entityName}`;

  return <ResultList entity={entity} title={title} entityName={entityName} />;
};

export default CityList;
