import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import TopCinemaMapDetails from './topCinema/TopCinemaMapDetails';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';
import CityChoices from '../navigation/CityChoices';
import NChoices from '../navigation/NChoices';
import SearchTopHeader from '../search/SearchTopHeader';
import { Context } from '../Store';
const TopCinemaMap = () => {
  const { city, n } = useParams();
  const [state] = useContext(Context);

  const currentCity = state.navigationCities.find((c) => c.key === city);
  const cityName = currentCity !== undefined ? currentCity.name : '';
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Top Cinemas: ${cityName}`;

  const apiPath = `${process.env.REACT_APP_API_URL}/top/cinema/map/city/${city}/${n}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <>
      <div className="row mb-4">
        <div className="row mb-4">
          {cityName !== '' && <h1 className="col-12 text-center">Top Cinemas for {cityName}</h1>}
          {state.navigationCities.length > 0 && cityName === '' && (
            <h1 className="col-12 text-center">Top Cinemas for- Error unknown city!</h1>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-between align-items-start">
          <NChoices />
          <CityChoices />
          <SearchTopHeader />
        </div>
      </div>
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <>
                <TopCinemaMapDetails cinemas={data.cinemas} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopCinemaMap;
