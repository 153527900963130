import PropTypes from 'prop-types';

import AggregationShow from './AggregationShow';

const AggregationList = ({
  aggregations,
  include = [],
  extraClass = '',
  nestedTitle = null,
  entityName = null,
}) => {
  // return null;
  return (
    <>
      {nestedTitle && <h1>{nestedTitle}</h1>}

      <div className={`results-aggregations ${extraClass}`}>
        {/* <div className="d-flex flex-row justify-content-between mb-2"> */}
        {/* <div className="">
          <h2>Filters</h2>
        </div>
        <div className="">
          <Link
            className="btn btn-outline-danger btn-sm me-1"
            to={{
              pathname: location.pathname,
              search: search,
            }}
          >
            Reset All
          </Link>
        </div> */}
        {/* </div> */}

        {Object.keys(aggregations).map((key) => {
          return (
            <AggregationShow
              key={key}
              aggregation={aggregations[key]}
              name={key}
              nestedTitle={nestedTitle}
              entityName={entityName}
            />
          );
        })}
      </div>
    </>
  );
};

// AggregationList.propTypes = {
//   aggregations: PropTypes.array,
//   include: PropTypes.array,
//   filterTitle: PropTypes.string,
// };

export default AggregationList;
