import ecaHome from '../images/eca-audience-large.jpg';

const Homepage = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Home`;

  return (
    <>
      <div className="row">
        <div className="col">
          {/* <img
            src={ecaHome}
            className="img-fluid"
            alt="Group of four cinema workers standing outside cinema"
          /> */}
          <div className="position-relative">
            <img
              src={ecaHome}
              className="homepage-image"
              alt="Group of four cinema workers standing outside cinema"
            />
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className={`rounded-5 p-3 text-decoration-none fs-3 text-center text-white text-uppercase`}
                style={{
                  backgroundColor: 'rgba(128, 128, 128, 0.5)',
                  backdropFilter: 'blur(5px)',
                  lineHeight: '1',
                  color: 'white',
                  textWrap: 'pretty',
                }}
              >
                European Cinema Audiences Entangled Histories And Shared Memories
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col">EUROPEAN CINEMA AUDIENCES ENTANGLED HISTORIES AND SHARED MEMORIES</div>
      </div> */}
    </>
  );
};

export default Homepage;
