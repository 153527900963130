import { useContext } from 'react';

import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';

import FilmCinemaRunCompareDetails from './topFilm/TopFilmCinemaCompareDetails';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';
import { Context } from '../Store';
const FilmCinemaRunCompare = () => {
  const [searchParams] = useSearchParams();
  const { city, film } = useParams();

  const [state] = useContext(Context);

  const currentCity = state.navigationCities.find((c) => c.key === city);
  const cityName = currentCity !== undefined ? currentCity.name : '';
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Compare Cinemas for Film shown in ${cityName}`;

  const apiPath = `${process.env.REACT_APP_API_URL}/v2/top/film/${film}/compare/city`;
  const { data, isLoading, error } = useAxios(
    apiPath,
    [],
    [apiPath, searchParams.get('dateFrom'), searchParams.get('dateTo')],
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <FilmCinemaRunCompareDetails
                // city={city}
                // cityName={cityName}
                cities={data.aggregations.city.buckets}
                film={data.film}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

FilmCinemaRunCompare.propTypes = {
  active: PropTypes.number,
};

export default FilmCinemaRunCompare;
