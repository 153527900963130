import ResultList from './results/ResultList';

const ScreeningList = () => {
  const entityName = 'Screennings';
  const entity = 'screening';

  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - ${entityName}}`;

  return <ResultList entity={entity} title={title} entityName={entityName} />;
};

export default ScreeningList;
