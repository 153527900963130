import { faFilm, faIndustry, faVideo, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, Link } from 'react-router-dom';

const EntityTags = ({ line }) => {
  const location = useLocation();
  const cinemas = line.cinemas.map((cinema) => {
    let query = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    let remove = false;

    if (Object.hasOwnProperty.bind(query)('cinemas')) {
      if (query['cinemas'].includes(cinema.id)) {
        remove = true;
        query['cinemas'] = query['cinemas'].filter((item) => item !== cinema.id);
      } else {
        query['cinemas'].push(cinema.id);
      }
    } else {
      query.cinemas = [cinema.id];
    }
    const search = queryString.stringify(query, {
      arrayFormat: 'bracket',
    });

    return (
      <li key={cinema.id} className="list-inline-item mb-1 ms-1 me-0">
        <div className="badge text-wrap bg-secondary rounded-pill">
          <FontAwesomeIcon icon={faVideo} />
          <span className="ms-2">{cinema.name}</span>
          {': '}
          <Link
            className="text-white"
            to={{
              pathname: `/cinema/${cinema.id}`,
            }}
          >
            View
          </Link>
          {' / '}
          <Link
            className="text-white"
            to={{
              pathname: `/interview`,
              search: search,
              hash: location.hash,
            }}
          >
            Show Interviews {remove && <span>[x]</span>}
          </Link>
        </div>
      </li>
    );
  });

  const people = line.people.map((person) => {
    let query = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    let remove = false;

    if (Object.hasOwnProperty.bind(query)('people')) {
      if (query['people'].includes(person.id)) {
        remove = true;
        query['people'] = query['people'].filter((item) => item !== person.id);
      } else {
        query['people'].push(person.id);
      }
    } else {
      query.people = [person.id];
    }
    const search = queryString.stringify(query, {
      arrayFormat: 'bracket',
    });

    return (
      <li key={person.id} className="list-inline-item ms-1 me-0">
        <div className="badge text-wrap bg-secondary rounded-pill">
          <FontAwesomeIcon icon={faUser} />
          <span className="ms-2">{person.name}</span>
          {': '}
          <Link
            className="text-white"
            to={{
              pathname: `/person/${person.id}`,
            }}
          >
            View
          </Link>
          {' / '}
          <Link
            className="text-white"
            to={{
              pathname: `/interview`,
              search: search,
              hash: location.hash,
            }}
          >
            Show Interviews {remove && <span>[x]</span>}
          </Link>
        </div>
      </li>
    );
  });

  const companies = line.companies.map((company) => {
    let query = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    let remove = false;

    if (Object.hasOwnProperty.bind(query)('companies')) {
      if (query['companies'].includes(company.id)) {
        remove = true;
        query['companies'] = query['companies'].filter((item) => item !== company.id);
      } else {
        query['companies'].push(company.id);
      }
    } else {
      query.companies = [company.id];
    }
    const search = queryString.stringify(query, {
      arrayFormat: 'bracket',
    });

    return (
      <li key={company.id} className="list-inline-item ms-1 me-0">
        <div className="badge text-wrap bg-secondary rounded-pill">
          <FontAwesomeIcon icon={faIndustry} />
          <span className="ms-2">{company.name}</span>
          {': '}
          <Link
            className="text-white"
            to={{
              pathname: `/company/${company.id}`,
            }}
          >
            View
          </Link>
          {' / '}
          <Link
            className="text-white"
            to={{
              pathname: `/interview`,
              search: search,
              hash: location.hash,
            }}
          >
            Show Interviews {remove && <span>[x]</span>}
          </Link>
        </div>
      </li>
    );
  });

  const films = line.films.map((film) => {
    let query = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    let remove = false;

    if (Object.hasOwnProperty.bind(query)('films')) {
      if (query['films'].includes(film.id)) {
        remove = true;
        query['films'] = query['films'].filter((item) => item !== film.id);
      } else {
        query['films'].push(film.id);
      }
    } else {
      query.films = [film.id];
    }
    const search = queryString.stringify(query, {
      arrayFormat: 'bracket',
    });

    return (
      <li key={film.id} className="list-inline-item ms-1 me-0">
        <div className="badge text-wrap bg-secondary rounded-pill">
          <FontAwesomeIcon icon={faFilm} />
          <span className="ms-2">{film.name}</span>
          {': '}
          <Link
            className="text-white"
            to={{
              pathname: `/film/${film.id}`,
            }}
          >
            View
          </Link>
          {' / '}
          <Link
            className="text-white"
            to={{
              pathname: `/interview`,
              search: search,
              hash: location.hash,
            }}
          >
            Show Interviews {remove && <span>[x]</span>}
          </Link>
        </div>
      </li>
    );
  });

  const entities = [...cinemas, ...people, ...companies, ...films];

  if (entities.length === 0) {
    return null;
  }
  return <ul className="list-inline text-end ms-1 mb-1">{entities}</ul>;
};
EntityTags.propTypes = {
  line: PropTypes.object,
  isLink: PropTypes.bool,
};

export default EntityTags;
