import { useContext } from 'react';

import { Link } from 'react-router-dom';
import { useParams, useLocation, matchPath } from 'react-router-dom';

import { Context } from '../Store';

const NChoices = () => {
  const [state] = useContext(Context);
  const location = useLocation();
  const { n, period, city, rank } = useParams();

  const buttons = state.nResults.map((choice) => {
    let pathname = '';
    if (matchPath('/top/cinema/:city/:timePeriod/:n', location.pathname)) {
      pathname = `/top/cinema/${city}/${period}/${choice.key}`;
    } else if (matchPath('/top/film/:city/:timePeriod/:n/:rank', location.pathname)) {
      pathname = `/top/film/${city}/${period}/${choice.name}/${rank}`;
    } else if (matchPath('/top/cinema/:city/:timePeriod/:n/map', location.pathname)) {
      pathname = `/top/cinema/${city}/${period}/${choice.name}/map`;
    }
    return (
      <Link
        className={'btn btn-sm ' + (n === choice.key ? 'btn-primary' : 'btn-outline-primary')}
        key={choice.key}
        to={{
          pathname: pathname,
          search: location.search,
        }}
      >
        {choice.name}
      </Link>
    );
  });
  return <div className="btn-group me-2 mb-3">{buttons}</div>;
};
export default NChoices;
