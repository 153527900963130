import {
  // interpolateSpectral,
  // interpolateRainbow,
  // interpolateCubehelixDefault,
  interpolateViridis,
  // scaleLinear,
  hsl,
  // rgb,
} from 'd3';

// const colors1 = [
//   '#003f5c',
//   '#2f7c4a',
//   '#665191',
//   '#a05195',
//   '#d45087',
//   '#f95d6a',
//   '#ff7c43',
//   '#ffa600',
// ];
// // Subtract 3% lightness from scheme for each row.
// const colors2 = [
//   '#00496b',
//   '#348952',
//   '#5f4b86',
//   '#954b8b',
//   '#d1427e',
//   '#f94e5c',
//   '#ff7033',
//   '#f09c00',
// ];
// const colors3 = [
//   '#00537a',
//   '#389458',
//   '#58467c',
//   '#8b4682',
//   '#ce3675',
//   '#f83f4e',
//   '#ff6524',
//   '#e09200',
// ];
// const colors4 = [
//   '#005e8a',
//   '#3c9f5f',
//   '#514072',
//   '#814178',
//   '#c5306e',
//   '#f83041',
//   '#ff5b14',
//   '#d18800',
// ];
// const colors5 = [
//   '#006999',
//   '#41aa65',
//   '#4a3b68',
//   '#773c6f',
//   '#b92d67',
//   '#f72233',
//   '#ff5005',
//   '#b37400',
// ];
// const colors6 = [
//   '#0073a8',
//   '#45b56c',
//   '#43355f',
//   '#6d3765',
//   '#ac2a60',
//   '#f61326',
//   '#f54900',
//   '#b37400',
// ];

// //Merge into single color scheme
// export const colors = []
//   .concat(colors1)
//   .concat(colors6)
//   .concat(colors2)
//   .concat(colors5)
//   .concat(colors3)
//   .concat(colors4);

// export const colorSchemes = [
//   [colors1[0], colors2[0], colors3[0], colors4[0], colors6[0]],
//   [colors1[1], colors2[1], colors3[1], colors4[1], colors6[1]],
//   [colors1[2], colors2[2], colors3[2], colors4[2], colors6[2]],
//   [colors1[3], colors2[3], colors3[3], colors4[3], colors6[3]],
//   [colors1[4], colors2[4], colors3[4], colors4[4], colors6[4]],
//   [colors1[5], colors2[5], colors3[5], colors4[5], colors6[5]],
//   [colors1[6], colors2[6], colors3[6], colors4[6], colors6[6]],
//   [colors1[7], colors2[7], colors3[7], colors4[7], colors6[7]],
// ];

// export const cityColors = {
//   bari: colors1[0],
//   brno: colors1[1],
//   gent: colors1[2],
//   gothenburg: colors1[3],
//   leicester: colors1[4],
//   magdeburg: colors1[5],
//   rotterdam: colors1[6],
// };

// export const rankColors = {
//   Minor: 'darkGreen',
//   Intermediate: 'darkBlue',
//   Inactive: 'dimGray',
//   Major: 'darkOrange',
//   Elite: 'darkRed',
//   'Minor Seasonal': 'gold',
// };

export const codeColor = (code) => {
  let categoryColor = hsl(
    interpolateViridis((code.categoryPosition - 1) / (code.categoryTotal - 1)),
  );
  categoryColor.l = 0.4;
  // let hslCategoryColor = hsl(categoryColor);
  // hslCategoryColor.l = 0.8;

  // var subcategoryColor = scaleLinear()
  //   .domain([1, code.subCategoryTotal])
  //   .range([categoryColor, hslCategoryColor]);

  // const color = subcategoryColor(code.subCategoryPosition);
  const color = categoryColor;
  return color;
};

export const ecaFill = '#6c757d';

export const COLORS = [
  '#00b7d2',
  '#cc1076',
  '#2CA02C',
  '#ffce3c',
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
];
export const COLORPAIRS = [
  ['#00b7d2', '#cc1076'],
  ['#2CA02C', '#ffce3c'],
  ['#1f77b4', '#ff7f0e'],
];

export default COLORS;
