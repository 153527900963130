import { useRef, useEffect } from 'react';

import { FeatureGroup, useMap } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';

const MarkerLayer = ({ markers, isLoading }) => {
  const map = useMap();
  const featureGroupRef = useRef();
  const [searchParams] = useSearchParams();

  searchParams.delete('lat');
  searchParams.delete('lng');
  searchParams.delete('zoom');

  useEffect(() => {
    if (!map) {
      return;
    }

    const layers = featureGroupRef.current._layers;

    if (Object.keys(layers).length > 1) {
      const bounds = featureGroupRef.current.getBounds();
      if (bounds.isValid()) {
        map.fitBounds(bounds, { padding: [100, 100] });
      }
    }
  }, [map, isLoading]);

  return <FeatureGroup ref={featureGroupRef}>{markers}</FeatureGroup>;
};
export default MarkerLayer;
