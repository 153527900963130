import ResultList from './results/ResultList';

const CinemaList = () => {
  const entity = 'cinema';
  const entityName = 'Cinemas';
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - ${entityName}`;

  return <ResultList entity={entity} title={title} entityName={entityName} />;
};

export default CinemaList;
