import React from 'react';

import PropTypes from 'prop-types';
import { Tooltip, Pie, PieChart, Cell, ResponsiveContainer, Legend } from 'recharts';

import { COLORS } from '../../utils/colors';

const SimplePie = ({ data, filterName }) => {
  const style = {
    padding: 6,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
  };

  const SimpleTooltip = ({ active, payload, filterName }) => {
    if (active) {
      const value = payload && payload.length ? payload[0].payload : null;
      return (
        <div className="area-chart-tooltip" style={style}>
          <p>
            <strong>{filterName + ' : '}</strong>
            <span>{value ? value.key : ' -- '}</span> <em>{value ? value.doc_count : ' -- '}</em>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Legend layout="vertical" verticalAlign="top" align="top" />

        <Pie
          dataKey="doc_count"
          nameKey="key"
          data={data}
          innerRadius={100}
          label
          legendType="square"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<SimpleTooltip filterName={filterName} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

SimplePie.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.array,
  data: PropTypes.array,
  filterName: PropTypes.string,
};

export default SimplePie;
