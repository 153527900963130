import React from 'react';

import { faFilm, faIndustry, faVideo, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import AggregationBucket from './AggregationBucket';

const icons = {
  cinemas: <FontAwesomeIcon icon={faVideo} />,
  films: <FontAwesomeIcon icon={faFilm} />,
  people: <FontAwesomeIcon icon={faUser} />,
  companies: <FontAwesomeIcon icon={faIndustry} />,
};
const Aggregation = ({ aggregation }) => {
  return aggregation === undefined ? null : (
    <div className="card">
      <div
        className="card-header"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#filter-${aggregation.key}`}
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        {icons[aggregation.key]}
        <span className="ms-2">{aggregation.name}</span>
      </div>
      <div className="collapse" id={`filter-${aggregation.key}`}>
        <div className="card-body ">
          <ul className="list-inline">
            {aggregation.values.map((value) => (
              <AggregationBucket
                key={value.key}
                value={value}
                aggregationName={aggregation.key}
                reverse={aggregation.reverse}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

Aggregation.propTypes = {
  aggregation: PropTypes.object,
};

export default Aggregation;
