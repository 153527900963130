import React from 'react';

import PropTypes from 'prop-types';

import BaseContainer from './BaseContainer';
import CinemaMarker from '../Marker/CinemaMarker';

const CinemaContainer = ({ cinema }) => {
  return cinema.address.position != null ? (
    <BaseContainer
      markers={<CinemaMarker cinema={cinema} />}
      center={cinema.address.position}
      initialZoom={14}
      showCityNav={false}
      showAggregations={false}
      mapHeight="650px"
    />
  ) : (
    <span>No Geo data for this cinema, unable to display on map.</span>
  );
};

CinemaContainer.propTypes = {
  cinema: PropTypes.object,
};

export default CinemaContainer;
