import { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import TopFilmCinemaRunContainer from '../../map/Container/TopFilmCinemaRunContainer';
import { COLORS } from '../../utils/colors';
import { formatISODate } from '../../utils/dates';
import DateSlider from '../../widget/DateSlider';

const CinemaRunCinema = ({ cinemas, city }) => {
  const [searchParams] = useSearchParams();

  //TODO - add a utile to validate dates
  const dateFrom = searchParams.get('dateFrom') ? searchParams.get('dateFrom') : '1951-01-01';
  const dateTo = searchParams.get('dateFrom') ? searchParams.get('dateTo') : '1953-12-31';

  const getDaysIndex = (start, end) => {
    const dayIndex = [];
    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      const date = new Date(dt);
      const dateKey = formatISODate(date);
      dayIndex.push(dateKey);
    }
    return dayIndex;
  };

  const [fromIndex, setFromIndex] = useState(0);
  const [toIndex, setToIndex] = useState(1);
  const [fromFilter, setFromFilter] = useState(Date(dateFrom));
  const [toFilter, setToFilter] = useState(new Date(dateTo));
  const [daysIndex, setDaysIndex] = useState(getDaysIndex(new Date(dateFrom), new Date(dateTo)));
  const [values, setValues] = useState([0, daysIndex.length]);

  useEffect(() => {
    if (daysIndex.length > 0 && values[0] !== values[1]) {
      let newFromIndex = values[0];
      let newToIndex = values[1] - 1;
      let fromFilter = new Date(daysIndex[newFromIndex]);
      let toFilter = new Date(daysIndex[newToIndex]);
      setFromIndex(newFromIndex);
      setToIndex(newToIndex);
      setFromFilter(fromFilter);
      setToFilter(toFilter);
    }
  }, [values, daysIndex]);

  const getDaysArray = (start, end) => {
    const dataObject = {};
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      // set midday to avoid DST change over giving wrong day for ISOString below
      let date = new Date(dt);
      date.setHours(12);
      const dateKey = formatISODate(date);
      const dateFormatted = date.toLocaleDateString('en-GB', options);
      dataObject[dateKey] = { date: dateFormatted };
    }
    return dataObject;
  };

  const TiltedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {payload.value}
        </text>
      </g>
    );
  };

  const rechartStackedBars = cinemas.map((cinema, index) => {
    const colorIndex = index % COLORS.length;
    const venueName =
      city === 'all'
        ? cinema.venueInfo.name + '(' + cinema.venueInfo.city + ')'
        : cinema.venueInfo.name;
    return (
      <Bar
        key={index}
        dataKey={venueName}
        stroke={COLORS[colorIndex]}
        fill={COLORS[colorIndex]}
        stackId={'a'}
      />
    );
  });

  const rechartBrushStackedBars = cinemas.map((cinema, index) => {
    const colorIndex = index % COLORS.length;

    const venueName =
      city === 'all'
        ? cinema.venueInfo.name + '(' + cinema.venueInfo.city + ')'
        : cinema.venueInfo.name;
    return (
      <Bar
        key={index}
        dataKey={venueName}
        stroke={COLORS[colorIndex]}
        fill={COLORS[colorIndex]}
        dot={false}
        stackId={'a'}
      />
    );
  });

  const legendPayload = cinemas.map((cinema, index) => {
    const colorIndex = index % COLORS.length;

    const venueName =
      city === 'all'
        ? cinema.venueInfo.name + '(' + cinema.venueInfo.city + ')'
        : cinema.venueInfo.name;
    return {
      value: venueName,

      type: 'rect',
      color: COLORS[colorIndex],
    };
  });
  const countCinemas = cinemas.length;
  const cinemaKeyRows = Math.ceil(countCinemas / 4);
  const cinemaKeyHeight = 40 * cinemaKeyRows;

  const chartHeight = 300 + cinemaKeyHeight;

  const dayList = getDaysArray(new Date(dateFrom), new Date(dateTo));

  let firstActiveDateIndex = 366;
  cinemas.forEach((cinema, index) => {
    cinema.time_period.buckets.forEach((run) => {
      const date = run.key_as_string;
      if (typeof dayList[date] !== 'undefined') {
        const dateIndex = Object.keys(dayList).findIndex((e) => e === date);
        if (dateIndex < firstActiveDateIndex) {
          firstActiveDateIndex = dateIndex;
        }
        const venueName =
          city === 'all'
            ? cinema.venueInfo.name + '(' + cinema.venueInfo.city + ')'
            : cinema.venueInfo.name;
        dayList[date][venueName] = run.doc_count;
      }
    });
  });
  const rechartData = Object.keys(dayList).map(function (key) {
    return dayList[key];
  });

  const cinemaTableRows = cinemas.map((cinema, index) => {
    return (
      <tr key={index}>
        <td>{cinema.venueInfo.name}</td>
        <td>{cinema.venueInfo.city}</td>
        <td>{cinema.runList.count.value}</td>
        <td>{cinema.runList.days.value}</td>
      </tr>
    );
  });

  const stackedRechart = (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <BarChart
        data={rechartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={<TiltedAxisTick />} height={100} />
        <YAxis interval={3} />
        <Tooltip />
        <Legend
          verticalAlign="top"
          height={36}
          wrapperStyle={{ top: 0, left: 25 }}
          payload={legendPayload}
        />
        <ReferenceLine y={0} stroke="#000" />
        <Brush
          dataKey="date"
          height={30}
          stroke="#8884d8"
          startIndex={fromIndex}
          endIndex={toIndex}
          // onChange={brushChange}
        >
          <BarChart>{rechartBrushStackedBars}</BarChart>
        </Brush>
        {rechartStackedBars}
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <ul className="list-unstyled">
      <>
        <div className="row">
          <div className="col-md">{stackedRechart}</div>
        </div>
        <div className="row">
          <div className="col-md">
            <TopFilmCinemaRunContainer cinemas={cinemas} dateFrom={fromFilter} dateTo={toFilter} />
            <DateSlider
              daysIndex={daysIndex}
              firstActiveDateIndex={firstActiveDateIndex}
              values={values}
              setValues={setValues}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <table className="table">
              <thead>
                <tr>
                  <th>Cinema</th>
                  <th>City</th>
                  <th>Runs</th>
                  <th>Screenings</th>
                </tr>
              </thead>
              <tbody>{cinemaTableRows}</tbody>
            </table>
          </div>
        </div>
      </>
    </ul>
  );
};

export default CinemaRunCinema;
