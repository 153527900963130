import { CircleMarker, Popup, Tooltip } from 'react-leaflet';
import { Link } from 'react-router-dom';

import COLORS from '../../utils/colors';
function CinemaCircleMarker({ index, cinema, dateFrom, dateTo }) {
  let min = 0;
  let max = 100;
  const circleMinRadius = 10;
  const circleBaseUnit = 20;
  const circleUnit = circleBaseUnit / (max - min + 1);

  let count = 0;
  for (const [ib, b] of cinema.time_period.buckets.entries()) {
    const date = new Date(b.key);
    if (date >= dateFrom && date <= dateTo) {
      count += b.doc_count;
    }
  }
  const radius = Math.round(circleMinRadius + (count - min + 1) * circleUnit);
  const colorIndex = index % COLORS.length;

  return count > 0 ? (
    <CircleMarker
      center={cinema.venueInfo.geoData.split(', ')}
      color={'white'}
      weight={2}
      fillColor={COLORS[colorIndex]}
      fillOpacity={0.8}
      radius={radius}
    >
      <Popup>
        <h1>
          <Link to={'/cinema/' + cinema.venueInfo.id}>{cinema.venueInfo.name}</Link>{' '}
        </h1>
        <p>{count} screenings</p>
      </Popup>
      <Tooltip>{cinema.venueInfo.name}</Tooltip>
    </CircleMarker>
  ) : null;
}

export default CinemaCircleMarker;
