import { timeFormat } from 'd3-time-format';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Label,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

import { COLORPAIRS } from '../../utils/colors';

const FilmCinemaTimeline = ({ film, city, cities, ranks }) => {
  const renderTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;
      const dateFormatString = '%e %B %Y';
      const formatTime = timeFormat(dateFormatString);

      const cityColor = cities.find((c) => c.key === data.city).color;
      const rankColor = ranks.find((r) => r.key === data.cinemaRank).color;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
          }}
        >
          <h1>
            {data.name}, <span style={{ color: cityColor }}>{data.cityName}</span> (
            <span style={{ color: rankColor }}>{data.cinemaRank}</span>)
          </h1>

          <p>First screening on {formatTime(data.date).trim()}</p>
          <p>
            <span>Screenings </span>
            {data.screenings}
          </p>
        </div>
      );
    }

    return null;
  };

  const dateFormatString = '%e %b';
  const formatTime = timeFormat(dateFormatString);

  const TiltedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    const formatTime = timeFormat(dateFormatString);
    const formattedTick = formatTime(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {formattedTick}
        </text>
      </g>
    );
  };

  const YAxisLabelLink = (props) => {
    const { value, filmId } = props;

    return (
      <foreignObject x="20" y="10" width="175" height="80">
        <Link to={`/film/${filmId}`}>{value}</Link>
      </foreignObject>
    );
  };

  const range = [16, 225];

  return (
    <ResponsiveContainer width="100%" height={100}>
      <ScatterChart
        width={800}
        height={100}
        margin={{
          top: 10,
          right: 50,
          bottom: 40,
          left: 0,
        }}
      >
        <XAxis
          type="number"
          dataKey="date"
          scale="time"
          // interval={0}
          interval={'preserveStartEnd'}
          // tick={{ fontSize: 14 }}
          tickLine={{ transform: 'translate(0, -6)' }}
          tickFormatter={timeFormat(dateFormatString)}
          tick={TiltedAxisTick}
          domain={[film.film.fromUnix, film.film.toUnix]}
        />
        <YAxis
          type="number"
          dataKey="index"
          name={film.film.title}
          height={10}
          width={200}
          tick={false}
          tickLine={false}
          axisLine={false}
          // label={{ width: 195, offset: 5, value: film.film.title, position: 'insideRight', content={YAxisLableLink} }}
        >
          <Label
            // width={195}
            // offset={5}
            value={film.film.title}
            filmId={film.film.id}
            // position="insideRight"
            content={YAxisLabelLink}
          />
        </YAxis>
        <ZAxis type="number" dataKey="screenings" range={range} />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          wrapperStyle={{ zIndex: 100 }}
          content={renderTooltip}
        />
        <Scatter data={film.cinemas} fill={COLORPAIRS[0][0]}>
          {film.cinemas.map((entry, index) => {
            const cityColor = cities.find((c) => c.key === entry.city).color;
            const rankColor = ranks.find((r) => r.key === entry.cinemaRank).color;

            const cellColor = city === 'all' ? cityColor : rankColor;
            return <Cell key={`cell-${index}`} fill={cellColor} />;
          })}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

FilmCinemaTimeline.propTypes = {
  film: PropTypes.object,
  city: PropTypes.string,
};

export default FilmCinemaTimeline;
