import { useState, useEffect } from 'react';

import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'bootstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, Link } from 'react-router-dom';

const Speakers = ({ speakers }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    var myCollapse = document.getElementById('speakers');
    var bsCollapse = new Collapse(myCollapse, { toggle: false });
    isOpen ? bsCollapse.show() : bsCollapse.hide();
  }, [isOpen]);

  return (
    <div className="card">
      <div
        className="card-header"
        type="button"
        aria-expanded="false"
        aria-controls="codes"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        Speakers{' '}
        {isOpen ? (
          <FontAwesomeIcon icon={faMinusSquare} />
        ) : (
          <FontAwesomeIcon icon={faPlusSquare} />
        )}
      </div>
      <div className="collapse" id="speakers">
        <div className="card-body ">
          <ul className="list-inline">
            {speakers.values.map((speaker) => {
              let remove = false;
              const query = queryString.parse(location.search, {
                arrayFormat: 'bracket',
              });

              if (Object.hasOwnProperty.bind(query)('speaker')) {
                if (query['speaker'].includes(speaker.key)) {
                  remove = true;
                  query['speaker'] = query['speaker'].filter((item) => item !== speaker.key);
                } else {
                  query['speaker'].push(speaker.key);
                }
              } else {
                query.speaker = [speaker.key];
              }
              const search = queryString.stringify(query, {
                arrayFormat: 'bracket',
              });

              const buttonClass =
                speaker.doc_count > 0 ? 'btn-primary' : 'btn-outline-primary disabled';

              return (
                <li key={speaker.key} className="list-inline-item">
                  <Link
                    className={`btn btn-sm mb-2 ${buttonClass}`}
                    to={{
                      pathname: location.pathname,
                      search: search,
                    }}
                    aria-disabled={speaker.doc_count === 0}
                  >
                    {speaker.key} ({speaker.doc_count}) {remove && <span>[x]</span>}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

Speakers.propTypes = {
  codes: PropTypes.array,
};

export default Speakers;
