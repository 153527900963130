import PropTypes from 'prop-types';

import CinemaMarker from './CinemaMarker';
const SearchMarkers = ({ markerType, cinemas }) => {
  const markers = Object.values(cinemas).map((cinema) => {
    return <CinemaMarker key={cinema.id} cinema={cinema} markerType={markerType}></CinemaMarker>;
  });
  return markers;
};

SearchMarkers.prototype = {
  markerType: PropTypes.string,
  cinema: PropTypes.array,
};

export default SearchMarkers;
