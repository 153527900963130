import { useSearchParams } from 'react-router-dom';

import useAxios from './useAxios';

const useFormOptions = (apiPath) => {
  const [searchParams] = useSearchParams();

  const exclude = [];
  exclude.forEach((key) => {
    searchParams.delete(key);
  });
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath, searchParams.toString()]);

  return {
    options: data ? data : [],
    isLoading: isLoading,
    error: error,
  };
};

export default useFormOptions;
