import React from 'react';

import pinhead from '../../images/pinhead.png';
import pushpin from '../../images/pushpin.png';

function ThumbnailIcon({ cinema }) {
  let visible = 'invisible';
  let marker = pinhead;
  let iconWidth = 20;
  let iconHeight = 20;
  let rankVisible = 'rank-invisible';
  let showMarkerImage = true;
  let rankPin = false;

  if (showMarkerImage) {
    visible = 'visible';
    marker = pushpin;
    iconWidth = 25;
    iconHeight = 50;
  }

  if (rankPin) {
    rankVisible = 'rank-visible';
  }
  return (
    <>
      <div className={'rank rank-' + cinema.rankcss + ' ' + rankVisible}>
        <img className="pushpin" src={marker} width={iconWidth} height={iconHeight} />
        <div className={'cinema-images ' + visible}>
          {cinema.images.slice(0, 4).map((image, index) => {
            return (
              <img
                key={image.id}
                src={image.srcThumb}
                width={100}
                className={'img-space img-number-' + (index + 1)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ThumbnailIcon;
