import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import COLORS from '../utils/colors';

const AggregationPieChart = ({ buckets }) => {
  const getColor = (key, idx) => {
    return COLORS[idx % COLORS.length];
  };

  const makeRechartsPieBarData = (buckets) => {
    const rcdata = buckets.map((bucket) => {
      let rcbucket = {};
      rcbucket['key'] = bucket['key'];
      rcbucket['Total'] = bucket['doc_count'];

      return rcbucket;
    });

    return rcdata;
  };

  return (
    <ResponsiveContainer>
      <PieChart width={200} height={200}>
        <Pie data={makeRechartsPieBarData(buckets)} nameKey="key" dataKey="Total" stroke="none">
          {buckets.map((entry, idx) => (
            <Cell key={idx} fill={getColor(entry.key, idx)} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default AggregationPieChart;
