import { timeFormat } from 'd3-time-format';
import PropTypes from 'prop-types';
import { useSearchParams, Link } from 'react-router-dom';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from './CustomTooltip';
import useValidateDate from '../../hooks/useValidateDate';
import TopFilmCinemaCompareContainer from '../../map/Container/TopFilmCinemaCompareContainer';
import SearchTopHeader from '../../search/SearchTopHeader';
import { COLORS } from '../../utils/colors';
import { formatISODate } from '../../utils/dates';

const FilmCinemaRunCompareDetails = ({ cities, film }) => {
  const [searchParams] = useSearchParams();

  const { dateFrom, dateTo } = useValidateDate();

  const activeCities = cities.slice().filter((city) => city.doc_count > 0);

  const maps = activeCities.map((city) => {
    return (
      <div className="col-xl" key={city.key}>
        <TopFilmCinemaCompareContainer
          cinemas={city.venue.buckets}
          showAggregations={false}
          showCityNav={false}
          mapHeight={'400px'}
        />
        <p>
          Total screenings: {city.doc_count}{' '}
          <Link
            to={{
              pathname: `/film/top/${city.key}/1y/20/all`,
              search: searchParams.toString(),
            }}
          >
            View top films shown in {city.cityDisplayName.buckets[0].key}
          </Link>
        </p>
      </div>
    );
  });

  function getDaysArray(start, end) {
    const dataObject = {};
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };

    for (var arr = [], dt = start; dt <= end; dt.setMonth(dt.getMonth() + 1)) {
      // set midday to avoid DST change over giving wrong day for ISOString below
      let date = new Date(dt);
      date.setHours(12);
      const dateKey = formatISODate(date);
      const dateFormatted = date.getTime();

      dataObject[dateKey] = { date: dateFormatted };
    }
    return dataObject;
  }

  const dayList = getDaysArray(dateFrom, dateTo);
  let plots = [];
  let cityData = {};
  activeCities.map((city, index) => {
    cityData[city.key] = [];
    // Object.keys(dayList).map(function(key) {});
  });
  let popstatMax = 0;

  activeCities.map((city, index) => {
    city.popstat_series.buckets.map((datum) => {
      const date = datum.key_as_string;
      if (typeof dayList[date] !== 'undefined' && datum.popstat.value !== 0) {
        if (datum.popstat.value > popstatMax) {
          popstatMax = datum.popstat.value;
        }
        cityData[city.key].push({
          date: dayList[date].date,
          label: city.key,
          popstat: datum.popstat.value.toFixed(2),
        });
      }
    });
  });

  plots = Object.keys(cityData).map((city, index) => {
    return <Scatter key={index} data={cityData[city]} fill={COLORS[index]} shape="cross" />;
  });
  const xDomain = [dateFrom, dateTo];
  const ySteps = 5;
  const yInc = 5;
  const yMax = Math.ceil(popstatMax / ySteps / yInc) * ySteps * yInc;
  const yDomain = [0, yMax];

  const TiltedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    const formatTime = timeFormat('%B %Y');
    const formattedTick = formatTime(new Date(payload.value));

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {formattedTick}
        </text>
      </g>
    );
  };

  const getTicks = (startDate, endDate) => {
    const ticks = [];

    for (let dt = startDate; dt <= endDate; dt.setMonth(dt.getMonth() + 1)) {
      ticks.push(dt.getTime(0));
    }

    return ticks;
  };

  const getYTicks = (popstatMax) => {
    const ySteps = 5;
    const yInc = 5;
    let yDelta = Math.ceil(popstatMax / ySteps / yInc) * yInc;

    if (yDelta < yInc) {
      yDelta = yInc;
    }
    const yMax = Math.ceil(popstatMax / ySteps / yInc) * ySteps * yInc;
    const ticks = [];

    for (let y = 0; y <= yMax; y += yDelta) {
      ticks.push(y);
    }
    return ticks;
  };

  const ticks = getTicks(dateFrom, dateTo);
  const yTicks = getYTicks(popstatMax);

  return (
    <>
      <>
        <div className="d-flex flex-wrap justify-content-between align-items-start">
          <h1>
            Screenings Across Cities for{' '}
            <Link to={'/film/' + film.id}>
              {film.title} {film.year && ' (' + film.year + ')'}
              {film.productionCountry && ' (' + film.productionCountry + ')'}
            </Link>{' '}
          </h1>
          <SearchTopHeader />
        </div>
        <div className="row">
          <div className="col-xl">
            <ResponsiveContainer width={'100%'} height={400}>
              <ScatterChart
                width={1000}
                height={600}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 200,
                  left: 20,
                }}
              >
                <CartesianGrid />
                <XAxis
                  type="number"
                  dataKey="date"
                  // hasTick
                  name="Month"
                  tickFormatter={timeFormat('%B %Y')}
                  scale="time"
                  domain={xDomain}
                  interval={0}
                  tick={<TiltedAxisTick />}
                  ticks={ticks}
                >
                  <Label value="Month" offset={80} position="bottom" />
                </XAxis>
                <YAxis
                  type="number"
                  domain={yDomain}
                  ticks={yTicks}
                  dataKey="popstat"
                  name="Popstat"
                >
                  <Label value="Popstat" angle={-90} position="insideLeft" />
                </YAxis>
                <ZAxis dataKey="label" name="City" type="category" />
                <Tooltip content={<CustomTooltip filmCity="City" />} />
                {plots}
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="row">{maps}</div>
        {/* /TODO: animate */}
        {/* <div className="row">
            <div className="col-xl">
              <DateSlider daysIndex={daysIndex} updateVisuals={this.handleDateSlide} />
            </div>
          </div> */}
      </>
    </>
  );
};

FilmCinemaRunCompareDetails.propTypes = {
  film: PropTypes.object,
  cities: PropTypes.array,
};

export default FilmCinemaRunCompareDetails;
