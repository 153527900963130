import { useState, useContext } from 'react';

import L, { marker } from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, Pane, Circle } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { useSearchParams } from 'react-router-dom';

import { Context } from '../../Store';
import CityLocation from '../Layer/CityLocationLayer';
import CityTileLocation from '../Layer/CityTileLayer';
import CountyLayer from '../Layer/CountryLayer';
import MarkerLayer from '../Layer/MarkerLayer';
import AggregationList from '../Navigation/AggregationList';
import CityNavigation from '../Navigation/CityNavigation';
import ZoomDrag from '../Navigation/ZoomDrag';

const BaseContainer = ({
  markers,
  center = [50, 10],
  initialZoom = 4,
  showCityNav = true,
  showMarkerNav = false,
  showAggregations = false,
  isLoading = false,
  aggregations = [],
  mapHeight = 'calc(100dvh - 180px)',
}) => {
  const [searchParams] = useSearchParams();

  const [state] = useContext(Context);

  const tms = false;
  const minZoom = 4;
  const maxZoom = 18;
  const [map, setMap] = useState(null);
  const [opacity, setOpacity] = useState(0.8);

  const isLatitude = (lat) => {
    return isFinite(lat) && Math.abs(lat) <= 90;
  };

  const isLongitude = (lng) => {
    return isFinite(lng) && Math.abs(lng) <= 180;
  };

  if (searchParams.get('lat') && searchParams.get('lng')) {
    const lat = parseFloat(searchParams.get('lat'));
    const lng = parseFloat(searchParams.get('lng'));
    if (isLatitude(lat) && isLongitude(lng)) {
      center = [lat, lng];
    }
  }

  if (
    searchParams.get('zoom') &&
    parseInt(searchParams.get('zoom'), 10) >= minZoom &&
    parseInt(searchParams.get('zoom'), 10) <= maxZoom
  ) {
    initialZoom = parseInt(searchParams.get('zoom'), 10);
  }

  const goToCity = (cityId) => {
    const center = state.cities.find((city) => city.id === cityId).center;
    const zoom = state.cities.find((city) => city.id === cityId).defaultZoom;

    map && map.setView(center, zoom);
  };

  const updateOpacity = (e) => {
    setOpacity(e.target.value);
  };
  return (
    // <div className="map-wrapper">
    <div className="d-flex flex-row">
      {(showCityNav || showAggregations) && (
        <div className="map-filters pe-4" style={{ width: '300px', overflow: 'scroll' }}>
          <div style={{}}>
            {showAggregations && (
              <>
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <AggregationList aggregations={aggregations} />
                )}
              </>
            )}
            {showCityNav && map && (
              <div className="collapse show" id="mapOptions">
                <CityNavigation
                  map={map}
                  cities={showCityNav ? state.cities : {}}
                  showMarkerNav={showMarkerNav}
                  onClick={goToCity}
                  opacity={opacity}
                  onSlide={updateOpacity}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="map-map flex-fill" style={{ height: mapHeight }}>
        <MapContainer
          center={center}
          zoom={initialZoom}
          scrollWheelZoom={true}
          whenCreated={setMap}
          style={{ height: '100%' }}
          zoomControl={false}
        >
          <FullscreenControl position={'topright'} />
          <ZoomControl position={'bottomright'} />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            tms={tms}
            minZoom={minZoom}
            maxZoom={maxZoom}
            detectRetina={true}
          />
          <CityTileLocation
            cities={state.cities}
            tms={tms}
            minZoom={minZoom}
            maxZoom={maxZoom}
            opacity={opacity}
          />
          <CityLocation />
          <Pane name="markers" style={{ zIndex: '10000' }}>
            <MarkerLayer markers={markers} isLoading={isLoading} />
          </Pane>
          <Pane name="country" style={{ zIndex: '500' }}>
            <CountyLayer />
          </Pane>
          <ZoomDrag cities={state.cities} />
        </MapContainer>
      </div>
    </div>
  );
};

export default BaseContainer;
