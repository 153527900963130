import { useEffect } from 'react';

import { useMap } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';

const ZoomDrag = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const map = useMap();

  const onMove = () => {
    searchParams.set('lat', map.getCenter().lat);
    searchParams.set('lng', map.getCenter().lng);
    searchParams.set('zoom', map.getZoom());
    // TODO - can we do this without rerenders - which is slow. native js history push?
    // https://github.com/remix-run/react-router/issues/7634#issuecomment-1006997643
    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    map.on('moveend', onMove);
    map.on('zoomend', onMove);
    return () => {
      map.off('moveend', onMove);
      map.off('zoomend', onMove);
    };
  });
  return null;
};

export default ZoomDrag;
