import { useSearchParams, useParams } from 'react-router-dom';

import TopFilmPeriods from './TopFilmPeriods';
import useAxios from '../../hooks/useAxios';
import Error from '../../layout/Error';
import Loading from '../../layout/Loading';

const TopCinemaList = () => {
  const { city, period, n, rank } = useParams();

  const [searchParams] = useSearchParams();

  const apiPath = `${process.env.REACT_APP_API_URL}/top/film/city/${city}/${period}/${n}/${rank}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath, searchParams.toString()]);
  return (
    <>
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <>
                <TopFilmPeriods periods={data.aggregations.time_period.buckets} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopCinemaList;
