import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import ScreeningDetail from './screening/ScreeningDetail';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const ScreeningShow = () => {
  const { slug } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - People`;

  const apiPath = `${process.env.REACT_APP_API_URL}/screening/${slug}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);
  useEffect(() => {
    if (data.screening) {
      document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Screenings: ${data.screening.name}`;
    }
  }, [data]);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {data.screening && (
                <ScreeningDetail
                  screenings={data.screening}
                  activeScreening={data.activeScreening}
                  cinema={data.cinema}
                  date={data.date}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ScreeningShow;
