import { useState } from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';

import { COLORS } from '../../utils/colors';

const Rank = ({ ranks, series, screenings, titles }) => {
  const categorySet = new Set();

  ranks.forEach((rank) => {
    Object.keys(rank.categories).forEach((rankCategory) => {
      categorySet.add(rankCategory);
    });
  });

  const categoryOptions = [...categorySet].map((category) => {
    return { value: category, label: category };
  });

  const categoryScreenings = ranks.map((rank) => {
    let categoryScreenings = { rank: rank.rank };
    Object.keys(rank.categories).forEach((key) => {
      categoryScreenings[key] = rank.categories[key].screenings;
    });
    return categoryScreenings;
  });
  const categoryTitles = ranks.map((rank) => {
    let categoryTitles = { rank: rank.rank };
    Object.keys(rank.categories).forEach((key) => {
      categoryTitles[key] = rank.categories[key].titles;
    });
    return categoryTitles;
  });

  const ranksData = ranks.map((rank) => {
    return {
      rank: rank.rank,
      screenings: rank.screenings,
      titles: rank.titles,
      ...rank.categories,
    };
  });
  const initialActiveRanks = {
    rank: ranksData,
    categoryScreenings,
    categoryTitles,
  };
  const [activeCategories, setActiveCategories] = useState(categoryOptions);
  const [activeRanks, setActiveRanks] = useState(initialActiveRanks);

  const activeCategoriesChange = (e) => {
    const compare = (a, b) => {
      return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
    };

    if (e?.length > 0) {
      setActiveCategories(e.sort(compare));
    } else {
      setActiveCategories([]);
    }
    if (e?.length > 0) {
      const active = e.map((category) => {
        return category.value;
      });

      // const newRanks = ranks.map(rank => {
      //   const filtered = Object.keys(rank.categories)
      //     .filter(key => active.includes(key))
      //     .reduce((obj, key) => {
      //       obj[key] = rank.categories[key];
      //       return obj;
      //     }, {});
      //   return { rank: rank.rank, screenings: rank.screenings, titles: rank.titles, ...filtered };
      // });
      const ranksData = ranks.map((rank) => {
        const filtered = Object.keys(rank.categories)
          .filter((key) => active.includes(key))
          .reduce((obj, key) => {
            obj[key] = rank.categories[key];
            return obj;
          }, {});
        return { rank: rank.rank, screenings: rank.screenings, titles: rank.titles, ...filtered };
      });

      const categoryScreenings = ranks.map((rank) => {
        const filtered = Object.keys(rank.categories)
          .filter((key) => active.includes(key))
          .reduce((obj, key) => {
            obj[key] = rank.categories[key].screenings;
            return obj;
          }, {});
        return { rank: rank.rank, ...filtered };
      });
      const categoryTitles = ranks.map((rank) => {
        const filtered = Object.keys(rank.categories)
          .filter((key) => active.includes(key))
          .reduce((obj, key) => {
            obj[key] = rank.categories[key].titles;
            return obj;
          }, {});
        return { rank: rank.rank, ...filtered };
      });

      const newActiveRanks = {
        rank: ranksData,
        categoryScreenings,
        categoryTitles,
      };

      setActiveRanks(newActiveRanks);
    } else {
      const ranksData = ranks.map((rank) => {
        return { rank: rank.rank, screenings: rank.screenings, titles: rank.titles };
      });

      const categoryScreenings = ranks.map((rank) => {
        return { rank: rank.rank };
      });
      const categoryTitles = ranks.map((rank) => {
        return { rank: rank.rank };
      });

      const newActiveRanks = {
        rank: ranksData,
        categoryScreenings,
        categoryTitles,
      };
      setActiveRanks(newActiveRanks);
    }
  };

  const handleClick = (data, index) => {
    // console.log('update table', data, index);
  };

  const bars = Object.keys(activeCategories).map((category, index) => {
    return (
      <Bar
        key={activeCategories[category].value}
        dataKey={activeCategories[category].value}
        fill={COLORS[index % COLORS.length]}
        onMouseOver={handleClick}
      />
    );
  });

  const tableData = activeRanks.rank.map((activeRank) => {
    const table = Object.keys(activeRank)
      .filter((key) => {
        return (
          key !== 'rank' &&
          key !== 'screenings' &&
          key !== 'titles' &&
          key !== 'categoryScreenings' &&
          key !== 'categoryTitles'
        );
      })
      .map((key) => {
        return (
          <tr key={key}>
            <th>{key}</th>
            <td>{activeRank[key].screenings.toLocaleString()}</td>
            <td
              title={`${activeRank[
                key
              ].screenings.toLocaleString()} out of ${activeRank.screenings.toLocaleString()} screenings for ${
                activeRank.rank
              } rank`}
            >
              {((activeRank[key].screenings / activeRank.screenings) * 100).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 },
              )}
              %
            </td>
            <td
              title={`${activeRank[
                key
              ].screenings.toLocaleString()} out of ${screenings.toLocaleString()} screenings for all ranks`}
            >
              {((activeRank[key].screenings / screenings) * 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </td>
            <td>{activeRank[key].titles.toLocaleString()}</td>
            <td
              title={`${activeRank[
                key
              ].titles.toLocaleString()} out of ${activeRank.titles.toLocaleString()} titles for ${
                activeRank.rank
              } rank`}
            >
              {((activeRank[key].titles / activeRank.titles) * 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </td>
            <td
              title={`${activeRank[
                key
              ].titles.toLocaleString()} out of ${titles.toLocaleString()} titles for all ranks`}
            >
              {((activeRank[key].titles / titles) * 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </td>
          </tr>
        );
      });
    return (
      <div className="col" key={activeRank.rank}>
        <h2>{activeRank.rank}</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Screenings</th>
              <th>Titles</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{activeRank.screenings.toLocaleString()}</td>
              <td>{activeRank.titles.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Screenings</th>
              <th>% of Rank Screenings</th>
              <th>% of All Screenings</th>
              <th>Titles</th>
              <th>% of Rank Titles</th>
              <th>% of All Titles</th>
            </tr>
          </thead>
          <tbody>{table}</tbody>
        </table>
      </div>
    );
  });

  const formatYAxis = (tickItem) => {
    return tickItem.toLocaleString();
  };

  return (
    <>
      <Select
        isMulti
        options={categoryOptions}
        onChange={activeCategoriesChange}
        value={activeCategories}
      />
      <ResponsiveContainer width={'100%'} height={400}>
        <BarChart
          data={
            series === 'screenings' ? activeRanks.categoryScreenings : activeRanks.categoryTitles
          }
          margin={{
            top: 10,
            right: 30,
            left: 30,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="rank" />
          {/* TODO tooltip */}
          {/* <Tooltip
            active={true}
            wrapperStyle={{ width: 800 }}
            position={{ y: 380, x: 80 }}
            // viewBox={{ x: 0, y: 0, width: 800, height: 400 }}
          /> */}
          <YAxis
            label={{
              value: series === 'screenings' ? 'Screenings' : 'Titles',
              angle: -90,
              position: 'insideLeft',
              dx: -20,
            }}
            tickFormatter={formatYAxis}
          />
          {/* TODO - set height in proportion to number of categories */}
          <Legend verticalAlign="top" height={100} />
          {bars}
        </BarChart>
      </ResponsiveContainer>
      <div className="row">{tableData}</div>
    </>
  );
};

Rank.propTypes = {
  ranks: PropTypes.array,
  screenings: PropTypes.number,
  titles: PropTypes.number,
};

export default Rank;
