import { useState, useEffect, useMemo } from 'react';

import { faList, faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
// TODO remove queryString replace with useSearchParams
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Codes from './interview/Codes';
import EcaVideo from './interview/EcaVideo';
import Filters from './interview/Filters';
import InterviewListItem from './interview/InterviewListItem';
import useInterviewResults from '../hooks/useInterviewResults';
import Loading from '../layout/Loading';

// import throttle from 'lodash.throttle';

const InterviewList = () => {
  const location = useLocation();
  // const history = useHistory();
  const navigate = useNavigate();
  const query = queryString.parse(location.search, { arrayFormat: 'bracket' });

  const initialKeyword = query.keyword ? query.keyword : '';
  const initialExact = query.exact === 'true' ? true : false;
  const initialActiveTab = location.hash === '#nav-list' ? '#nav-list' : '#nav-gallery';
  const [searchForm, setSearchForm] = useState({ keyword: initialKeyword, exact: initialExact });
  const [search, setSearch] = useState({ keyword: initialKeyword, exact: initialExact });
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  useEffect(() => {
    document.title = `European Cinema Audiences - Interviews`;
  }, []);

  const apiPath = `${process.env.REACT_APP_API_URL}/interview`;

  const { results, aggregations, codes, nbResults, isLoading, error } =
    useInterviewResults(apiPath);

  const updateLocation = () => {
    const query = queryString.parse(location.search, { arrayFormat: 'bracket' });
    if (search.keyword === '') {
      delete query.keyword;
    } else {
      query.keyword = search.keyword;
    }

    if (search.exact === false) {
      delete query.exact;
    } else {
      query.exact = 'true';
    }

    // history.push({
    //   pathname: `/interview`,
    //   search: queryString.stringify(query, { arrayFormat: 'bracket' }),
    //   hash: activeTab,
    // });
    navigate({
      to: `/interview`,
      search: queryString.stringify(query, { arrayFormat: 'bracket' }),
      hash: activeTab,
    });
  };

  const handleChange = (event) => {
    const value = event.target.name === 'exact' ? event.target.checked : event.target.value;

    setSearchForm({
      ...searchForm,
      [event.target.name]: value,
    });
  };

  const handleSearchChange = (newSearchFrom) => {
    setSearch({ ...newSearchFrom });
  };

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 400), []);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateLocation();
  };

  const handleTab = (event) => {
    event.preventDefault();
    setActiveTab(event.target.dataset.bsTarget);
  };

  useEffect(() => {
    debouncedSearchChange(searchForm);
  }, [searchForm, debouncedSearchChange]);

  useEffect(() => {
    updateLocation();
  }, [search, activeTab]);

  const galleryVideos = results.map((result) => {
    return (
      <div
        className="col-sm-12 col-md-6 col-lg-4 col-xl-3 p-3 interview-wrapper border"
        key={result.interview.id}
      >
        {result.interview.isVideoPublic ? (
          <div className="ratio ratio-16x9">
            <EcaVideo video={result.interview.video} interview={result.interview} ref={null} />
          </div>
        ) : (
          <img className="ratio ratio-16x9" src={result.interview.video.thumbnailSrc} />
        )}
        <figcaption className="figure-caption text-center mt-1">
          <Link
            to={{
              pathname: `/interview/${result.interview.id}`,
              search: location.search,
            }}
          >
            {result.interview.name} - {result.interview.city.displayName}{' '}
          </Link>
        </figcaption>
      </div>
    );
  });
  const listVideos = results.map((result) => {
    return <InterviewListItem key={result.interview.id} result={result} />;
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="search"
                name="keyword"
                value={searchForm.keyword}
                onChange={handleChange}
                className="form-control"
                placeholder="keyword…"
                aria-label="Keyword"
                aria-describedby="basic-addon1"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    name="exact"
                    defaultChecked={searchForm.exact}
                    onChange={handleChange}
                    aria-label="Checkbox to enable exact phrase search for previous search input"
                    className="form-check-input ms-0"
                    id="checkbox-exact"
                  />
                  <label className="form-check-label ms-3" htmlFor="checkbox-exact">
                    Exact
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <Codes codes={codes} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">{<Filters filters={aggregations} />} </div>
        </div>
        <div className="row">
          <div className="col-12">
            <>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <nav className="">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className={`nav-link ${location.hash !== '#nav-list' && 'active'}`}
                        id="nav-gallery-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-gallery"
                        type="button"
                        role="tab"
                        aria-controls="nav-gallery"
                        aria-selected="true"
                        onClick={handleTab}
                      >
                        <FontAwesomeIcon
                          icon={faGripHorizontal}
                          style={{ pointerEvents: 'none' }}
                        />{' '}
                        Gallery
                      </button>
                      <button
                        className={`nav-link ${location.hash === '#nav-list' && 'active'}`}
                        id="nav-list-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-list"
                        type="button"
                        role="tab"
                        aria-controls="nav-list"
                        aria-selected="false"
                        onClick={handleTab}
                      >
                        <FontAwesomeIcon icon={faList} style={{ pointerEvents: 'none' }} /> List
                      </button>
                    </div>
                  </nav>
                  <div>
                    Number of matching interviews {nbResults}
                    {/* , phrase count = 123456789 */}
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={`tab-pane fade ${location.hash !== '#nav-list' && 'show active'}`}
                      id="nav-gallery"
                      role="tabpanel"
                      aria-labelledby="nav-gallery-tab"
                    >
                      <div className="d-flex flex-row flex-wrap">{galleryVideos}</div>
                    </div>
                    <div
                      className={`tab-pane fade ${location.hash === '#nav-list' && 'show active'}`}
                      id="nav-list"
                      role="tabpanel"
                      aria-labelledby="nav-list-tab"
                    >
                      <div className="d-flex flex-row">
                        <div className="p-2 col-3 border">
                          <strong>Interview</strong>
                        </div>
                        <div className="p-2 col-7 border">
                          <strong>Lines Matching Search</strong>
                        </div>
                        <div className="p-2 col-2 border">
                          <strong>Codes for Whole Interview</strong>
                        </div>
                      </div>
                      {listVideos}
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewList;
