import React, { Component } from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';

const AggregationBucket = ({ aggregationName, value, reverse }) => {
  const location = useLocation();
  let query = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  });

  let remove = false;
  if (Object.hasOwnProperty.bind(query)(aggregationName)) {
    if (query[aggregationName].includes(value.key)) {
      remove = true;
      query[aggregationName] = query[aggregationName].filter((item) => item !== value.key);
    } else {
      query[aggregationName].push(value.key);
    }
  } else {
    query[aggregationName] = [value.key];
  }

  const search = queryString.stringify(query, {
    arrayFormat: 'bracket',
  });

  const name = value.name ? value.name : value.key;

  return (
    <li className="list-inline-item">
      <Link
        className="btn btn-secondary btn-sm mb-2 rounded-pill"
        to={{
          pathname: location.pathname,
          search: search,
        }}
      >
        {name}{' '}
        {value.doc_count > 0 && <span>({reverse ? value.term_count : value.doc_count})</span>}{' '}
        {remove && <span>[x]</span>}
      </Link>
    </li>
  );
};

AggregationBucket.propTypes = {
  aggregationName: PropTypes.string,
  value: PropTypes.object,
  reverse: PropTypes.bool,
};

export default AggregationBucket;
