import { useState, useEffect } from 'react';

import axios from 'axios';
import queryString from 'query-string';
import { useLocation, Link } from 'react-router-dom';

import ItemRow from './results/ItemRow';
import TableHeading from './results/TableHeading';
import Loading from '../layout/Loading';

const Search = () => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Search`;
  }, []);

  const location = useLocation();

  const [cities, setCities] = useState({ items: [], columns: [] });
  const [cinemas, setCinemas] = useState({ items: [], columns: [] });
  const [films, setFilms] = useState({ items: [], columns: [] });
  const [screenings, setScreenings] = useState({ items: [], columns: [] });
  const [people, setPeople] = useState({ items: [], columns: [] });
  const [companies, setCompanies] = useState({ items: [], columns: [] });
  const [interviews, setInterviews] = useState({ items: [], columns: [] });
  const [transcriptLines, setTranscriptLines] = useState({ items: [], columns: [] });

  const [isLoading, setIsLoading] = useState(true);
  const apiPath = `${process.env.REACT_APP_API_URL}/search`;

  const qs = queryString.parse(location.search);
  const keyword = qs.keyword ?? '';

  useEffect(() => {
    setIsLoading(true);

    axios.get(`${apiPath}${location.search}`, { withCredentials: true }).then((res) => {
      const cities = res.data.results.city;
      const cinemas = res.data.results.cinema;
      const films = res.data.results.film;
      const screenings = res.data.results.screening;
      const people = res.data.results.person;
      const companies = res.data.results.company;
      const interviews = res.data.results.interview;
      const transcriptLines = res.data.results.transcriptLine;
      setCities(cities);
      setCinemas(cinemas);
      setFilms(films);
      setScreenings(screenings);
      setPeople(people);
      setCompanies(companies);
      setInterviews(interviews);
      setTranscriptLines(transcriptLines);

      setIsLoading(false);
    });

    // document.getElementById('main').scroll(0, 0);
  }, [location.search, apiPath]);

  return (
    <div>
      <div className="row">
        <div className="col">
          <h1 className="mb-3 pb-3">Search Results{keyword && ` for "${keyword}"`}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {cities.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Cities</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={cities.columns} showImage={false} sort={false} />
                      <tbody>
                        {cities.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={cities.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/city',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more cities
                    </Link>
                  </div>
                </div>
              )}
              {cinemas.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Cinemas</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={cinemas.columns} showImage={true} sort={false} />
                      <tbody>
                        {cinemas.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={cinemas.columns}
                            item={item}
                            showImage={true}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/cinema',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more cinemas
                    </Link>
                  </div>
                </div>
              )}
              {films.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Films</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={films.columns} showImage={false} sort={false} />
                      <tbody>
                        {films.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={films.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/film',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more films
                    </Link>
                  </div>
                </div>
              )}
              {screenings.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Screenings</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={screenings.columns} showImage={false} sort={false} />
                      <tbody>
                        {screenings.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={screenings.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/screening',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more screenings
                    </Link>
                  </div>
                </div>
              )}
              {people.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">People</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={people.columns} showImage={false} sort={false} />
                      <tbody>
                        {people.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={people.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/person',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more people
                    </Link>
                  </div>
                </div>
              )}
              {companies.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Companies</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={companies.columns} showImage={false} sort={false} />
                      <tbody>
                        {companies.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={companies.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/company',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more companies
                    </Link>
                  </div>
                </div>
              )}
              {interviews.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Interviews</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover table-responsive results">
                      <TableHeading columns={interviews.columns} showImage={false} sort={false} />
                      <tbody>
                        {interviews.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={interviews.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: '/interview',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more interviews
                    </Link>
                  </div>
                </div>
              )}
              {transcriptLines.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Interview Text</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={transcriptLines.columns}
                        showImage={false}
                        sort={false}
                      />
                      <tbody>
                        {transcriptLines.items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={transcriptLines.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {/* <Link
                      to={{
                        pathname: '/interview/lines',
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more transcript lines
                    </Link> */}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
