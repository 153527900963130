import BaseContainer from './BaseContainer';
import TopFilmCompareMarkers from '../Marker/TopFilmCompareMarkers';

const TopFilmCinemaCompareContainer = ({ cinemas }) => {
  // const [markerType, setmarkerType] = useState('thumbnail');

  // const handleMarkerType = newmarkerType => {
  //   setmarkerType(newmarkerType);
  // };

  return (
    <BaseContainer
      showMarkerNav={false}
      // handleMarkerType={handleMarkerType}
      showCityNav={false}
      showAggregations={false}
      mapHeight="400px"
      markers={<TopFilmCompareMarkers cinemas={cinemas} />}
    />
  );
};

export default TopFilmCinemaCompareContainer;
