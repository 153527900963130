import { Link } from 'react-router-dom';

import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const VisualisationList = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Visualisations`;

  const apiPath = `${process.env.REACT_APP_API_URL}/visualisation`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  const entities = data?.entities ? data.entities : [];
  const types = data?.types ? data.types : [];

  const rankCharts = [
    { key: 'productionCountry', name: 'Combined Production Countries' },
    { key: 'productionCountryCategory', name: ' Production Countries Category' },
    { key: 'coProductionCountry', name: 'Production Country' },
    { key: 'filmYear', name: 'Film Year' },
    { key: 'filmYearCategory', name: 'Film Year Category' },
    { key: 'genre', name: 'Film Genre' },
    { key: 'genreCategory', name: 'Film Genre Category' },
  ];

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <div className="row">
                <h1 className="col-12 text-center">Visualisations</h1>
              </div>
              <h2>Activity</h2>
              <div className="mb-4">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  <div className="col">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          Activity: <small>City Activity</small>
                        </h5>
                        <p className="card-text">Compare the Screening Activity across City</p>
                      </div>
                      <div className="card-footer">
                        <Link to="city/activity">Line</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">Price Proxy</h5>
                        <p className="card-text">Compare Price Proxy for Cinemas by City</p>
                      </div>
                      <div className="card-footer">
                        <Link to="/price-proxy/bari">Table</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2>Temporal</h2>
              <div className="mb-4">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  <div className="col">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          Temporal: <small>Screening</small>
                        </h5>
                        <p className="card-text">Compare Screening Categories by Year and City</p>
                      </div>
                      <div className="card-footer">
                        <Link to="timeline/all/false/genre/Comedy/Action">Timeline / Map</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          Temporal: <small>Film Circulation</small>
                        </h5>
                        <p className="card-text">
                          Compare Film Circulation by City / Rank over Time
                        </p>
                      </div>
                      <div className="card-footer">
                        <Link to="timeline/film/cinema/bari">Timeline</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="mb-3">Cinema Ranks</h2>
              <div className="mb-4">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  {rankCharts.map((rankChart) => {
                    return (
                      <div key={rankChart.key} className="col">
                        <div className="card h-100">
                          <div className="card-body">
                            <h5 className="card-title">
                              Cinema Rank: <small>{rankChart.name}</small>
                            </h5>
                            <p className="card-text">
                              Compare the distribution of {rankChart.name} across Cinema Rank
                            </p>
                          </div>
                          <div className="card-footer">
                            <Link to={`rank/${rankChart.key}/1y/screenings`}>Line</Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <h2>Insights</h2>
              {entities.map((entity) => (
                <div className="mb-4" key={entity.entity}>
                  <h4 className="mb-3">{entity.name}</h4>
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    {entity.filters.map((filter) => {
                      return (
                        <div key={filter.filter} className="col">
                          <div className="card h-100">
                            <div className="card-body">
                              <h5 className="card-title">
                                {entity.name}: <small>{filter.name}</small>
                              </h5>
                              <p className="card-text">
                                Compare the distribution of {filter.name} across {entity.name}
                              </p>
                            </div>
                            <div className="card-footer">
                              {types.map((type) => {
                                return (
                                  <Link
                                    className="me-2"
                                    key={type.type}
                                    to={`insight/${entity.entity}/${filter.filter}/${type.type}`}
                                  >
                                    {type.name}
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VisualisationList;
