import { useSearchParams } from 'react-router-dom';

import useAxios from './useAxios';

const useInterviewResult = (apiPath) => {
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);
  return {
    interview: data.interview ? data.interview : { interviewLines: [], transcriptLines: [] },
    video: data.video
      ? data.video
      : {
          thumbnailSrc: '',
          progressiveSrc: '',
          hlsSrc: '',
          vttSrc: '',
          ecaVttSrc: '',
        },
    isLoading: isLoading,
    error: error,
  };
};

export default useInterviewResult;
