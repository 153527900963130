import { useContext } from 'react';

import { useSearchParams, useParams, Link } from 'react-router-dom';

import CityCompareChoices from './topFilm/CityCompareChoices';
import TopFilmCinemaRun from './topFilm/TopFilmCinemaRun';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';
import ActiveFilm from '../navigation/ActiveFilm';
import SearchTopHeader from '../search/SearchTopHeader';
import { Context } from '../Store';

const TopFilmCinema = () => {
  const { city, n, rank } = useParams();
  const [state] = useContext(Context);
  const [searchParams] = useSearchParams();

  const currentCity = state.navigationCities.find((c) => c.key === city);
  const cityName = currentCity !== undefined ? currentCity.name : '';
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Cinemas for Film in ${cityName}`;

  const apiPath = `${process.env.REACT_APP_API_URL}/v2/top/film/city/${city}/${n}/${rank}/screening`;
  const { data, isLoading, error } = useAxios(
    apiPath,
    [],
    [apiPath, searchParams.get('dateFrom'), searchParams.get('dateTo')],
  );
  const active = searchParams.get('active') ? parseInt(searchParams.get('active')) : 1;
  const film = data.aggregations ? data.aggregations.film_id.buckets[active - 1] : null;
  return (
    <>
      {state.navigationCities.length > 0 && cityName === '' ? (
        <div className="row">
          <h1 className="col-12 text-center">Top Films for- Error unknown city!</h1>
        </div>
      ) : (
        <>
          {film && (
            <div className="">
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {error.message !== null ? (
                    <Error error={error} />
                  ) : (
                    <>
                      <div className="row mb-2">
                        <div className="d-flex flex-wrap justify-content-between align-items-start">
                          {
                            <h2 className="text-left me-4">
                              <small>
                                <Link to={'/film/' + film.key}>
                                  {film.film_title.buckets[0].key}{' '}
                                  {film.film_year.buckets.length > 0 &&
                                    ' (' + film.film_year.buckets[0].key + ')'}
                                  {film.film_country.buckets.length > 0 &&
                                    ' (' + film.film_country.buckets[0].key + ')'}
                                </Link>
                              </small>
                            </h2>
                          }
                          <CityCompareChoices
                            city={city}
                            cityDisplayName={cityName}
                            choices={data.cityChoices}
                            film={film.key}
                            compare={film.compare}
                          />
                          <h3 className="text-left me-4">
                            <small>
                              Screenings in {cityName}, selected dates: {film.compare[cityName]},
                              1951 – 1953: {film.overview}
                            </small>
                          </h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12"></div>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between align-items-start">
                        <ActiveFilm films={data.aggregations.film_id.buckets} />
                        <SearchTopHeader />
                      </div>
                      <TopFilmCinemaRun city={city} film={film} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TopFilmCinema;
