import { useContext } from 'react';

import { Link } from 'react-router-dom';
import { useParams, useLocation, matchPath } from 'react-router-dom';

import { Context } from '../Store';

const PeriodChoices = () => {
  const [state] = useContext(Context);
  const location = useLocation();
  const { n, period, city, rank } = useParams();

  const buttons = state.timePeriods.map((choice) => {
    let pathname = '';
    if (matchPath('/top/cinema/:city/:timePeriod/:n', location.pathname)) {
      pathname = `/top/cinema/${city}/${choice.key}/${n}`;
    } else if (matchPath('/top/film/:city/:timePeriod/:n/:rank', location.pathname)) {
      pathname = `/top/film/${city}/${choice.key}/${n}/${rank}`;
    } else if (matchPath('/price-proxy/:city', location.pathname)) {
      pathname = `/price-proxy/${choice.key}`;
    }

    return (
      <Link
        className={
          'btn btn-sm ' + (period === choice.key ? 'btn-secondary' : 'btn-outline-secondary')
        }
        key={choice.key}
        to={{
          pathname: pathname,
          search: location.search,
        }}
      >
        {choice.name}
      </Link>
    );
  });
  return <div className="btn-group me-2 mb-3">{buttons}</div>;
};
export default PeriodChoices;
