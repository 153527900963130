import CityActivityList from './visualisation/CityActivityList';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const VisualisationCityActivity = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Visualisations City Activity`;
  const apiPath = `${process.env.REACT_APP_API_URL}/overview/activity/city`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <h1>City Activity</h1>
              <CityActivityList chartData={data.chart} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default VisualisationCityActivity;
