import { useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import TimelineContainer from '../../map/Container/TimelineContainer';
import Timeline from '../chart/Timeline';

const VisualisationScreeningComparisonDetails = ({ chartData, categories, cities, chartTitle }) => {
  let navigate = useNavigate();
  const { city1, city2, type, value1, value2 } = useParams();
  const [options, setOptions] = useState({ city1, city2, type, value1, value2 });
  const optionsChange = (e) => {
    const newOptions = { ...options, [e.target.name]: e.target.value };
    if (e.target.name === 'type') {
      const activeCategory = categories.find((category) => {
        return category.value === e.target.value;
      });

      newOptions.value1 = activeCategory.choices[0].value;
      newOptions.value2 = activeCategory.choices[1].value;
    }

    if (newOptions.value1 !== newOptions.value2) {
      setOptions(newOptions);

      const pathname = `/visualisation/timeline/${encodeURIComponent(
        newOptions.city1,
      )}/${encodeURIComponent(newOptions.city2)}/${encodeURIComponent(
        newOptions.type,
      )}/${encodeURIComponent(newOptions.value1)}/${encodeURIComponent(newOptions.value2)}`;

      navigate(pathname, { replace: true });
    }
  };

  const city1Options = cities.map((city) => {
    return (
      <option key={city.key} value={city.key}>
        {city.name}
      </option>
    );
  });

  const city2Options = [{ key: 'false', name: 'Select a comparison…' }, ...cities].map((city) => {
    return (
      <option key={city.key} value={city.key}>
        {city.name}
      </option>
    );
  });

  const categoryOptions = categories.map((category) => {
    return (
      <option key={category.value} value={category.value}>
        {category.name}
      </option>
    );
  });

  const activeCategory = categories.find((category) => {
    return category.value === options.type;
  });

  const categoryOptionChoices =
    typeof activeCategory === 'undefined'
      ? []
      : activeCategory.choices.map((choice) => {
          return (
            <option key={choice.value} value={choice.value}>
              {choice.name}
            </option>
          );
        });

  const title = (
    <>
      {chartTitle.type}{' '}
      <small>
        {chartTitle.value1} Vs. {chartTitle.value2} in {chartTitle.city}
      </small>
    </>
  );
  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="row">
          <h1 className="col-12">{title}</h1>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="city1">Choose first city for timeline:</label>
            <select id="city1" name="city1" onChange={optionsChange} value={options.city1}>
              {city1Options}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="city2">Choose second city for timeline:</label>
            <select id="city2" name="city2" onChange={optionsChange} value={options.city2}>
              {city2Options}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="type">Choose a category:</label>
            <select id="type" name="type" onChange={optionsChange} value={options.type}>
              {categoryOptions}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="value1">Choose a first option:</label>
            <select id="value1" name="value1" onChange={optionsChange} value={options.value1}>
              {categoryOptionChoices}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="value2">Choose a second option:</label>
            <select id="value2" name="value2" onChange={optionsChange} value={options.value2}>
              {categoryOptionChoices}
            </select>
          </div>
        </div>
        <div>
          <div className="visualisation-timeline">
            <div>
              <div style={{ width: '100%' }}>
                {chartData.map((year, index) => {
                  return (
                    <div key={year.year}>
                      <h2>{year.year}</h2>
                      <Timeline
                        cityCompare={city2 !== 'false'}
                        colorIndex={index}
                        types={year.categoryTypes}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', height: '400px' }}>
          <div className="mb-2" style={{ display: 'grid', height: '400px' }}>
            <TimelineContainer title={title} options={options} />
          </div>
          <div className="mb-2" style={{ display: 'grid', height: '400px' }}>
            <TimelineContainer title={title} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisualisationScreeningComparisonDetails;
