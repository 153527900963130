import { Link } from 'react-router-dom';

const CinemaImages = ({ cinema }) => {
  const gallery = cinema.images.map((image) => (
    <div key={image.id} className="m-2">
      <Link to={`/cinema/${cinema.id}/image/${image.id}`}>
        <img src={image.srcThumb} height="120" alt="title" className="border p-2" />
      </Link>
    </div>
  ));
  return (
    <>
      <div className="row" style={{ overflow: 'scroll' }}>
        <div className="col-md-12">
          <div className="d-flex">{gallery}</div>
        </div>
      </div>
    </>
  );
};
export default CinemaImages;
