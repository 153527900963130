import { useRef } from 'react';

import { faArrowRight, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import CodeTags from './CodeTags';
import EcaVideo from './EcaVideo';
import EntityTags from './EntityTags';

const InterviewListItem = ({ result }) => {
  const videoRef = useRef();
  const textLength = 10000;
  const location = useLocation();

  const transcriptLines = (
    <ul className="list-unstyled">
      {result.transcriptLineHits.map((transcriptLine) => {
        // const text =
        // transcriptLine.hit.content.length > textLength
        //   ? `${transcriptLine.hit.content.substring(0, textLength)}…`
        //   : transcriptLine.hit.content;
        return (
          <li key={transcriptLine.hit.id} className="interview-transcript-line">
            <div className="interview-transcript-line-before">
              {transcriptLine.before.map((before) => (
                <div key={before.id}>
                  <strong>{before.speaker}:</strong> {before.content}
                </div>
              ))}
            </div>
            <div className="interview-transcript-line-hit my-2">
              <Link
                to={{
                  pathname: `/interview/${result.interview.id}`,
                  search: `${location.search}&s=${transcriptLine.hit.start}`,
                }}
                title={transcriptLine.hit.content}
              >
                <strong>{transcriptLine.hit.speaker}:</strong> {transcriptLine.hit.content}
              </Link>
              <button
                className="btn btn-sm btn-outline-primary ms-2 pt-0 pe-1 pb-0 ps-1"
                onClick={() => videoRef.current.seek(transcriptLine.hit.start)}
              >
                <FontAwesomeIcon icon={faPlay} title={'Play interview from here'} />
              </button>
              <CodeTags codes={transcriptLine.hit.codes} />
              <EntityTags line={transcriptLine.hit} />
            </div>
            <div className="interview-transcript-line-before">
              {transcriptLine.after.map((after) => (
                <div key={after.id}>
                  <strong>{after.speaker}:</strong> {after.content}
                </div>
              ))}
            </div>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="interview-wrapper d-flex flex-row" key={result.interview.id}>
      <div className="p-2 col-3 border">
        {result.interview.isVideoPublic ? (
          <div className="ratio ratio-16x9">
            <EcaVideo video={result.interview.video} interview={result.interview} ref={videoRef} />
          </div>
        ) : (
          <img className="ratio ratio-16x9" src={result.interview.video.thumbnailSrc} />
        )}
        <figcaption className="figure-caption text-center mt-1">
          <Link
            to={{
              pathname: `/interview/${result.interview.id}`,
              search: location.search,
            }}
          >
            {result.interview.name} - {result.interview.city.displayName}{' '}
          </Link>
        </figcaption>
      </div>
      <div className="p-2 col-7 border">{result.interview.isCoded && transcriptLines}</div>
      <div className="p-2 col-2 border">
        {result.interview.isCoded && <CodeTags codes={result.interview.codes} />}
        {result.interview.isCoded && <EntityTags line={result.interview} />}
        {!result.interview.isCoded && (
          <strong>Interview has not been coded, subtitles have been used instead.</strong>
        )}
      </div>
    </div>
  );
};

export default InterviewListItem;
