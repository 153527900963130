import { faFileExcel, faFileCsv, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

import ItemRow from './ItemRow';
import Pagination from './Pagination';
import TableHeading from './TableHeading';
import AggregationList from '../../filterAggregation/AggregationList';
import useResults from '../../hooks/useResults';
import Error from '../../layout/Error';
import Loading from '../../layout/Loading';
import SearchHeader from '../../search/SearchHeader';
import SearchSummary from '../../search/SearchSummary';

const ResultList = ({ entity, title, entityName = null }) => {
  const location = useLocation();
  const showImage = location.pathname.startsWith('/cinema');
  document.title = title;
  const apiPath = `${process.env.REACT_APP_API_URL}/${entity}`;
  const dataPathCsv = `${process.env.REACT_APP_DOWNLOAD_URL}/${entity}/csv${location.search}`;
  const dataPathXlsx = `${process.env.REACT_APP_DOWNLOAD_URL}/${entity}/xlsx${location.search}`;
  const dataPathJson = `${process.env.REACT_APP_DOWNLOAD_URL}/${entity}/json${location.search}`;

  const { items, aggregations, nestedAggregations, pagination, columns, isLoading, error } =
    useResults(apiPath);

  return (
    <div className="results-page">
      {/* <div className="results-container">
        <div className="table-aggregations">
          <div style={{ width: '300px' }}>aggregation content</div>
        </div>
        <div className="results">
          <table>
            <tbody>
              <tr>
                <td>result content</td>
              </tr>
            </tbody>
          </table>
          <div className="pagination">pagination content</div>
        </div>
      </div> */}
      {/* <div className="results-container">
        <div className="aggregations">
          very long content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, adipisci
          tenetur. Error in officiis omnis veritatis, molestias deserunt perferendis, totam fuga
          quod, aliquam rem voluptate nihil illo modi ipsa optio.
        </div>
        <div className="results">Lorem ipsum dolor</div>
      </div> */}

      <div className="d-flex flex-row">
        <div className="col">
          <SearchHeader entity={entity} />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col">
          <SearchSummary pagination={pagination} />
        </div>
      </div>
      <div className="results-container">
        <div className="aggregations">
          <AggregationList aggregations={aggregations} />
          {Object.keys(nestedAggregations).map((key) => {
            const show =
              nestedAggregations[key]['filters'] &&
              Object.keys(nestedAggregations[key]['filters']).reduce((prev, current) => {
                return prev || nestedAggregations[key]['filters'][current].buckets.length > 0;
              }, false);

            return show ? (
              <div key={key}>
                <AggregationList
                  aggregations={nestedAggregations[key]['filters']}
                  nestedTitle={nestedAggregations[key]['filterName']}
                  entityName={entityName}
                />
              </div>
            ) : null;
          })}
        </div>
        <div className="results">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {error.message !== null ? (
                <Error error={error} />
              ) : (
                <>
                  {/* list and gallery view for each */}
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading columns={columns} showImage={showImage} />
                      <tbody>
                        {items.map((item) => (
                          <ItemRow
                            key={item.id}
                            columns={columns}
                            item={item}
                            showImage={showImage}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
          <div className="d-lg-flex justify-content-between my-2">
            <div className="pagination">
              <Pagination pagination={pagination} />
            </div>
            <div className="download-link">
              {!isLoading && (
                <>
                  <span className="me-1">Download results as:</span>
                  <div className="btn-group ">
                    <a className="btn btn-sm btn-primary border" href={dataPathCsv}>
                      <FontAwesomeIcon icon={faFileCsv} className="me-2" />
                      CSV
                    </a>
                    <a className="btn btn-sm btn-primary border" href={dataPathXlsx}>
                      <FontAwesomeIcon icon={faFileExcel} className="me-2" />
                      Excel
                    </a>
                    <a className="btn btn-sm btn-primary border" href={dataPathJson}>
                      <FontAwesomeIcon icon={faFileExport} className="me-2" />
                      JSON
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultList;
