import React, { useState, useCallback, useMemo, useContext } from 'react';

import { useEventHandlers } from '@react-leaflet/core';
import { GeoJSON, useMap } from 'react-leaflet';

import ecaCountriesGeoJson from '../../geoJson/eca_countries.json';
import { Context } from '../../Store';

function CountryLayer() {
  const [state] = useContext(Context);

  const map = useMap();

  const [zoom, setZoom] = useState(map.getZoom());

  const onChange = useCallback(() => {
    setZoom(map.getZoom());
  }, [map]);

  const handlers = useMemo(() => ({ zoom: onChange }), []);

  useEventHandlers({ instance: map }, handlers);

  const countriesStyle = (feature) => {
    const color =
      !state.error &&
      state.cities &&
      state.cities.find((city) => city.countryCode === feature.properties.ISO_A2)
        ? state.cities.find((city) => city.countryCode === feature.properties.ISO_A2).color
        : '#dddddd';

    if (zoom > 10) {
      return { color: color, weight: 2, opacity: 0, fillOpacity: 0 };
    } else if (zoom > 8) {
      return { color: color, weight: 2, opacity: 0.5, fillOpacity: 0.25 };
    } else if (zoom > 6) {
      return { color: color, weight: 2, opacity: 0.75, fillOpacity: 0.5 };
    } else {
      return { color: color, weight: 2, opacity: 0.99, fillOpacity: 0.85 };
    }
  };

  return <GeoJSON key={zoom} data={ecaCountriesGeoJson} style={countriesStyle} />;
}

export default CountryLayer;
