import ResultList from './results/ResultList';

const PersonList = () => {
  const entityName = 'People';
  const entity = 'person';
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - ${entityName}`;

  return <ResultList entity={entity} title={title} entityName={entityName} />;
};

export default PersonList;
