import React from 'react';

import {
  faCircle,
  faPlay,
  faSquare,
  faStar,
  faCertificate,
  faMapMarker,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function KeyIcon({ markerType = 'faCircle', color, size = '2x' }) {
  let icon = faCircle;
  switch (markerType) {
    case 'faCircle':
      icon = faCircle;
      break;
    case 'faPlay':
      icon = faPlay;
      break;
    case 'faSquare':
      icon = faSquare;
      break;
    case 'faCertificate':
      icon = faCertificate;
      break;
    case 'faStar':
      icon = faStar;
      break;
    case 'faMapMarker':
      icon = faMapMarker;
      break;

    default:
      icon = faCircle;
      break;
  }
  return (
    <div className={`fa-${size}`}>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={icon} color="white" opacity={0.8} />
        <FontAwesomeIcon icon={icon} color={color} transform="shrink-4" />
      </span>
    </div>
  );
}

export default KeyIcon;
