import { useContext } from 'react';

import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';

import { Context } from '../../Store';
import KeyIcon from '../Icon/KeyIcon';
import PinheadIcon from '../Icon/PinheadIcon';
import ThumbnailIcon from '../Icon/ThumbnailIcon';
import CinemaPopup from '../Popup/CinemaPopup';

const CinemaMarker = ({ cinema }) => {
  const [searchParams] = useSearchParams();
  const [state] = useContext(Context);

  const markerType = searchParams.get('markerType') || 'thumbnail';

  const thumbnailIconAnchor = [20, 50];
  const thumbnailIconSize = [50, 50];

  const thumbnailIcon = L.divIcon({
    iconAnchor: thumbnailIconAnchor,
    iconSize: thumbnailIconSize,
    html: ReactDOMServer.renderToString(<ThumbnailIcon cinema={cinema} />),
  });

  const pinheadIconAnchor = [10, 10];
  const pinheadIconSize = [20, 20];

  const pinheadIcon = L.divIcon({
    iconAnchor: pinheadIconAnchor,
    iconSize: pinheadIconSize,
    html: ReactDOMServer.renderToString(<PinheadIcon />),
  });

  const KeyIconAnchor = [12, 12];
  const KeyIconSize = [24, 24];
  const rankMarkerType =
    state.ranks && state.ranks.find((r) => r.name === cinema.rank)
      ? state.ranks.find((r) => r.name === cinema.rank).icon
      : 'faCircle';
  const seatingRangeColor =
    state.seatingRanges && state.seatingRanges.find((r) => r.name === cinema.seatingRange)
      ? state.seatingRanges.find((r) => r.name === cinema.seatingRange).color
      : 'red';

  const seatingRangeIcon = L.divIcon({
    iconAnchor: KeyIconAnchor,
    iconSize: KeyIconSize,
    html: ReactDOMServer.renderToString(
      <KeyIcon markerType={rankMarkerType} color={seatingRangeColor} />,
    ),
  });

  let opacity = 1.0;

  let icon = thumbnailIcon;
  switch (markerType) {
    case 'thumbnail':
      icon = thumbnailIcon;
      break;

    case 'pinehead':
      icon = pinheadIcon;
      break;

    case 'seatingRange':
      icon = seatingRangeIcon;
      break;

    default:
      icon = pinheadIcon;
      break;
  }

  return (
    <Marker opacity={opacity} position={cinema.address.position} icon={icon} cinemaId={cinema}>
      <CinemaPopup cinema={cinema} />
    </Marker>
  );
};

export default CinemaMarker;
