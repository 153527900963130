import { useState, useEffect } from 'react';

import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'bootstrap';
import PropTypes from 'prop-types';
// import queryString from 'query-string';
import { useLocation, Link } from 'react-router-dom';

import AggregationList from '../../filterAggregation/AggregationList';
import { codeColor } from '../../utils/colors';

const Filters = ({ filters }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const myCollapse = document.getElementById('interview-filters');
    const bsCollapse = new Collapse(myCollapse, { toggle: false });
    isOpen ? bsCollapse.show() : bsCollapse.hide();
  }, [isOpen]);

  return (
    <div className="card">
      <div
        className="card-header"
        type="button"
        aria-expanded="false"
        aria-controls="interview-filters"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        Filters{' '}
        {isOpen ? (
          <FontAwesomeIcon icon={faMinusSquare} />
        ) : (
          <FontAwesomeIcon icon={faPlusSquare} />
        )}
      </div>
      <div className="collapse" id="interview-filters">
        <div className="interview-aggregations">
          <AggregationList
            aggregations={filters}
            include={[]}
            filterTitle={null}
            extraClass="d-flex align-items-start justify-content-start flex-wrap"
          />
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  codes: PropTypes.array,
};

export default Filters;
