const CinemaSummary = ({ cinema }) => {
  return (
    <table className="table table-striped">
      <thead></thead>
      <tbody>
        <tr className="">
          <th>Name</th>
          <td>{cinema.name}</td>
        </tr>
        <tr className="">
          <th>Street</th>
          <td>{cinema.address.street}</td>
        </tr>
        <tr className="">
          <th>City</th>
          <td>
            {cinema.address.city.displayName} ({cinema.address.city.district})
          </td>
        </tr>
        <tr className="">
          <th>Location</th>
          <td>{cinema.venueLocation}</td>
        </tr>
        <tr className="">
          <th>Country</th>
          <td>{cinema.address.city.country}</td>
        </tr>
        <tr className="">
          <th>Seating Range</th>
          <td>{cinema.seatingRange}</td>
        </tr>
        <tr className="">
          <th>Venue Rank</th>
          <td>{cinema.venueRank}</td>
        </tr>
        <tr className="">
          <th>Turnover</th>
          <td>{cinema.turnover}</td>
        </tr>

        <tr className="">
          <th>Price Proxy</th>
          <td>
            {parseFloat(cinema.priceProxy).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </td>
        </tr>
        <tr className="">
          <th>Cinema Status Index </th>
          <td>
            {parseFloat(cinema.revenuePotential).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
        <tr className="">
          <th>Average Cinema Status Index</th>
          <td>
            {parseFloat(cinema.averageRevenuePotential).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
        <tr className="">
          <th>Cinema Weight</th>
          <td>
            {parseFloat(cinema.cinemaWeight).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
        <tr className="">
          <th>Active Cinema Weight</th>
          <td>
            {parseFloat(cinema.activeCinemaWeight).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>

        <tr className="">
          <th>Opening Date</th>
          <td>{cinema.openingDate}</td>
        </tr>
        <tr className="">
          <th>Closing Date</th>
          <td>{cinema.closingDate}</td>
        </tr>
        <tr className="">
          <th>Cinema Status</th>
          <td>{cinema.status}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default CinemaSummary;
