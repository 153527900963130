import { Link } from 'react-router-dom';

const PriceProxyTable = ({ n, ppYears, ppFilms, ppCinemas, ppCinemaCounts }) => {
  const tableYears = ppYears.map((year) => {
    return (
      <th colSpan={n} key={year}>
        {year}
      </th>
    );
  });

  const tableFilms = ppFilms.map((film, index) => {
    const yearIndex = (index % n) + 1;
    return (
      <th key={index} title={`${film.title}: Screenings: ${film.count}, Popstat: ${film.popstat}`}>
        <div>
          <Link
            key={index}
            to={{
              pathname: `/top/film/${film.city}/1y/20/all/cinema`,
              search: `active=${yearIndex}&dateFrom=${film.from}&dateTo=${film.to}&filmSort=screenings`,
            }}
          >
            {film.title} ({film.count}) ({film.popstat})
          </Link>
        </div>
      </th>
    );
  });

  const tableCinemas = ppCinemas.map((row, ri) => {
    return (
      <tr key={ri} className={row.cinema.class}>
        <th className="text-black">
          <Link to={'/cinema/' + row.cinema.id}>{row.cinema.name}</Link>
        </th>
        {row.data.map((cell, ci) => {
          let title = '';
          if (cell['score'] != '') {
            title = 'Rank: ' + cell['rank'] + ', First Screening: ' + cell['date_string'];
          }
          return (
            <td key={ci} title={title}>
              {cell['score']}
            </td>
          );
        })}
        <td>{row.aggregate.count}</td>
        <td>{row.aggregate.sum}</td>
        <td>{row.aggregate.average}</td>
      </tr>
    );
  });

  const tableCinemaCounts = ppCinemaCounts.map((value, index) => {
    if (index === 0) {
      return (
        <th key={index} title={value}>
          {value}
        </th>
      );
    }

    return (
      <td key={index} title={value}>
        {value}
      </td>
    );
  });

  return (
    <table className="table table-bordered border-primary table-sm table-responsive price-proxy">
      <thead>
        <tr>
          <th></th>
          {tableYears}
          <th colSpan={3}></th>
        </tr>
        <tr className="film-titles">
          <th>Cinemas</th>
          {tableFilms}
          <th>Count</th>
          <th>Sum</th>
          <th>Average</th>
        </tr>
      </thead>
      <tbody>
        {tableCinemas}
        <tr className="table-dark">{tableCinemaCounts}</tr>
      </tbody>
    </table>
  );
};

export default PriceProxyTable;
