import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EntityInterview from '../interview/EntityInterview'; // Import the missing component
import ScreeningAggregationChart from '../results/show/chart/ScreeningAggregationChart';
import ScreeningByCityChart from '../results/show/chart/ScreeningsByCityChart';
import DownloadData from '../results/show/DownloadData';
import MostPopular from '../results/show/MostPopular';

const FilmDetail = ({ film, screenings, linkAggregations, aggregations }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="d-inline">
            {film.title} {film.category.length > 0 && `(${film.category.join(', ')})`}
          </h1>
          {film.imdb && (
            <a
              className="ms-2"
              href={`https://www.imdb.com/title/${film.imdb}`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> IMDb
            </a>
          )}
        </div>
      </div>

      {screenings.some((screening) =>
        Object.keys(screening).some((key) => key !== 'date' && key !== 'date_string'),
      ) ? (
        <>
          <MostPopular linkAggregations={linkAggregations} aggregations={aggregations} />

          <ScreeningByCityChart screenings={screenings} id={film.id} screeningField="filmId" />

          <div className="row">
            {linkAggregations
              .filter((aggregation) => aggregation.values.length > 0)
              .map((aggregation) => (
                <ScreeningAggregationChart key={aggregation.key} aggregation={aggregation} />
              ))}
            {aggregations
              .filter((aggregation) => aggregation.values.length > 0)
              .map((aggregation) => (
                <ScreeningAggregationChart key={aggregation.key} aggregation={aggregation} />
              ))}
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col">
            <h2 className="mt-4 fs-5">No screenings recorded this film.</h2>
          </div>
        </div>
      )}
      <EntityInterview transcriptLines={film.transcriptLines} />

      <DownloadData
        entity="film"
        entityName="Film"
        id={film.id}
        screeningField="filmId"
        screeningValue={film.id}
      />
    </>
  );
};
export default FilmDetail;
