import { useState, forwardRef, useImperativeHandle } from 'react';

import Hls from 'hls.js';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ReactPlayer from 'react-player/file';
import { useLocation } from 'react-router-dom';
//set local hls for use by ReactPlayer
window.Hls = Hls;

const EcaVideo = forwardRef(({ video, interview }, ref) => {
  const location = useLocation();

  const query = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const initialStart = query.s ? +query.s : 0;

  const [player, setPlayer] = useState(null);
  const [start, setStart] = useState(initialStart);
  const [playing, setPlaying] = useState(true);

  const handleOnPlay = () => {
    if (start !== 0) {
      player.seekTo(start);
      setStart(0);
    }
    setPlaying(true);
  };
  const handleOnPause = () => {
    setPlaying(false);
  };
  const handleOnSeek = e => {
    setPlaying(true);
  };

  useImperativeHandle(ref, () => ({
    seek(seconds) {
      if (player.player === undefined) {
        setStart(seconds);
        player.handleClickPreview();
      } else {
        player.seekTo(seconds);
      }
    },
  }));

  return (
    <div className="ratio ratio-16x9">
      <ReactPlayer
        ref={player => {
          setPlayer(player);
        }}
        url={video.hlsSrc}
        controls={true}
        light={video.thumbnailSrc}
        onPlay={handleOnPlay}
        onSeek={handleOnSeek}
        onPause={handleOnPause}
        playing={playing}
        playsinline={true}
        pip={true}
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              crossOrigin: 'true',
            },
            hlsOptions: {
              capLevelToPlayerSize: true,
              capLevelOnFPSDrop: true,
            },
          },
          tracks: [
            {
              kind: 'subtitles',
              src: video.vttSrc,
              srcLang: 'English',
              default: true,
            },
            // {
            //   kind: 'subtitles',
            //   src: video.ecaVttSrc,
            //   srcLang: 'Nvivo',
            //   default: interview.isCoded,
            // },
          ],
        }}
      />
    </div>
  );
});

EcaVideo.propTypes = {
  video: PropTypes.object,
};

export default EcaVideo;
