import { useParams } from 'react-router-dom';

import VisualisationRankDetails from './visualisation/VisualisationRankDetails';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const VisualisationRank = () => {
  const { type, timePeriod } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Visualisations Rank`;

  const apiPath = `${process.env.REACT_APP_API_URL}/overview/rank/${type}/${timePeriod}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <VisualisationRankDetails
                chart={data.chart.data}
                categories={data.chart.categories}
                title={data.chart.title}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default VisualisationRank;
