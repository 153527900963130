import { Link, useLocation } from 'react-router-dom';

const CityCompareChoices = ({ city, cityDisplayName, choices, film, compare }) => {
  const location = useLocation();
  let compareLink = null;
  let linkText = '';
  if (city != 'all') {
    choices.forEach((choice) => {
      if (city !== choice.key && choice.key !== 'all' && compare[choice.name] > 0) {
        linkText += choice.name + ' (' + compare[choice.name] + ') ';
      }
    });

    linkText = linkText.trim();

    if (linkText === '') {
      compareLink = 'Only shown in ' + cityDisplayName;
    } else {
      compareLink = (
        <div>
          <Link
            className="btn btn-sm btn-success"
            to={'/top/film/cinema/compare/' + city + '/' + film}
            search={location.search}
          >
            {'Compare with: ' + linkText}
          </Link>
        </div>
      );
    }
  }

  return <div className="">{compareLink}</div>;
};

export default CityCompareChoices;
