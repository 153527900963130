import { useContext } from 'react';

import L from 'leaflet';
import { Link, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Context } from '../../Store';
import KeyIcon from '../Icon/KeyIcon';

const CityNavigation = ({ map, cities, showMarkerNav, onClick, opacity, onSlide }) => {
  const [searchParams] = useSearchParams();
  const [state] = useContext(Context);

  cities.forEach((city) => {
    city.visible = map.getBounds().contains(L.latLng(city.center));
  });
  const location = useLocation();

  const cityButtons = cities.map((city) => {
    return (
      <li key={city.id} className="nav-item">
        <button
          data-city={city.id}
          className={
            'btn btn-sm nav-city m-2' + (city.visible ? ' btn-primary' : ' btn-outline-primary')
          }
          onClick={() => onClick(city.id)}
        >
          {city.displayName}
        </button>
      </li>
    );
  });

  const markerButtons = state.markerTypes.map((marker) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('markerType', marker.key);
    return (
      <li key={marker.key} className="nav-item">
        <Link
          className="map-filter-link"
          to={{
            pathname: location.pathname,
            search: newSearchParams.toString(),
          }}
        >
          <input
            className="form-check-input"
            checked={(searchParams.get('markerType') || 'thumbnail') === marker.key}
            type="checkbox"
            readOnly={true}
            disabled={true}
          />
          <span className="ms-2">{marker.name}</span>
        </Link>
      </li>
    );
  });

  const ranksKey = state.ranks.map((rank) => {
    return (
      <li key={rank.key} className="nav-item">
        <div
          style={{
            display: 'inline-block',
            width: '40px',
            padding: '0',
          }}
        >
          <KeyIcon markerType={rank.icon} color={'dimGray'} size={'1x'} />
        </div>
        <span className="map-key-label">{rank.name}</span>
      </li>
    );
  });

  const seatingRangesKey = state.seatingRanges.map((seatingRange) => {
    return (
      <li key={seatingRange.key} className="nav-item">
        <div
          style={{
            display: 'inline-block',
            width: '40px',
            padding: '0',
          }}
        >
          <KeyIcon markerType="faCircle" color={seatingRange.color} size={'1x'} />
        </div>
        <span className="map-key-label">{seatingRange.name}</span>
      </li>
    );
  });

  return (
    <div className="">
      <div className="mb-2">
        <div
          className="map-heading map-collapsible-heading me-2"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-navigate-cities"
          aria-expanded="false"
          aria-controls="collapse-navigate-cities"
        >
          <span className="ms-2">Navigate to Cities</span>
        </div>
        <ul id="collapse-navigate-cities" className="nav me-1 collapse">
          {cityButtons}
        </ul>
      </div>

      {showMarkerNav && (
        <>
          <div
            className="map-heading map-collapsible-heading mb-2"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-map-options"
            aria-expanded="false"
            aria-controls="collapse-map-options"
          >
            <span className="ms-2">Options</span>
          </div>
          <div id="collapse-map-options" className="collapse">
            <div className="map-sub-heading ms-4">Map Opacity</div>
            <input
              id="slide"
              type="range"
              min="0"
              max="1"
              step="0.05"
              value={opacity}
              onChange={onSlide}
              className="form-range"
            />
            <div className="map-sub-heading ms-4">Markers:</div>
            <ul className="list-unstyled ms-4">{markerButtons}</ul>
          </div>

          <div className="">
            <div className="map-heading ms-4">Key</div>
            <div className="me-2">Seating Range:</div>
            <ul className="nav me-1">{seatingRangesKey}</ul>
            <div className="me-2">Rank:</div>
            <ul className="nav me-1">{ranksKey}</ul>
          </div>
        </>
      )}
    </div>
  );
};

export default CityNavigation;
