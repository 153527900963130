import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import EntityInterview from '../interview/EntityInterview'; // Import the missing component
import ScreeningAggregationChart from '../results/show/chart/ScreeningAggregationChart';
import ScreeningByCityChart from '../results/show/chart/ScreeningsByCityChart';
import DownloadData from '../results/show/DownloadData';
import MostPopular from '../results/show/MostPopular';

const PersonDetail = ({ person, screenings, linkAggregations, aggregations }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="d-inline">
            {person.name} (
            {person.type.map((type) => (
              <Link key={type} className="mx-2" to={`/company?filter_type_raw[]=${type}`}>
                {type}
              </Link>
            ))}
            )
          </h1>
          {person.imdbId && (
            <a
              className="ms-2"
              href={`https://www.imdb.com/name/${person.imdbId}`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> IMDb
            </a>
          )}
        </div>
      </div>

      {screenings.some((screening) =>
        Object.keys(screening).some((key) => key !== 'date' && key !== 'date_string'),
      ) ? (
        <>
          <MostPopular linkAggregations={linkAggregations} aggregations={aggregations} />

          <ScreeningByCityChart screenings={screenings} id={person.id} screeningField="personId" />

          <div className="row">
            {linkAggregations
              .filter((aggregation) => aggregation.values.length > 0)
              .map((aggregation) => (
                <ScreeningAggregationChart key={aggregation.key} aggregation={aggregation} />
              ))}
            {aggregations
              .filter((aggregation) => aggregation.values.length > 0)
              .map((aggregation) => (
                <ScreeningAggregationChart key={aggregation.key} aggregation={aggregation} />
              ))}
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col">
            <h2 className="mt-4 fs-5">No screenings recorded this person.</h2>
          </div>
        </div>
      )}

      <EntityInterview transcriptLines={person.transcriptLines} />

      <DownloadData
        entity="person"
        entityName="Person"
        id={person.id}
        screeningField="personId"
        screeningValue={person.id}
      />
    </>
  );
};
export default PersonDetail;
