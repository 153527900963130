import { useContext } from 'react';

import { Link } from 'react-router-dom';
import { useParams, useLocation, matchPath } from 'react-router-dom';

import { Context } from '../Store';

const CityChoices = () => {
  const [state] = useContext(Context);
  const location = useLocation();
  const { n, period, city, rank } = useParams();

  const buttons = state.navigationCities.map((choice) => {
    let pathname = '';
    if (matchPath('/top/cinema/:city/:timePeriod/:n', location.pathname)) {
      pathname = `/top/cinema/${choice.key}/${period}/${n}`;
    } else if (matchPath('/price-proxy/:city', location.pathname)) {
      pathname = `/price-proxy/${choice.key}`;
    } else if (matchPath('/top/film/:city/:timePeriod/:n/:rank', location.pathname)) {
      pathname = `/top/film/${choice.key}/${period}/${n}/${rank}`;
    } else if (matchPath('/top/cinema/:city/:timePeriod/:n/map', location.pathname)) {
      pathname = `/top/cinema/${choice.key}/${period}/${n}/map`;
    } else if (matchPath('/visualisation/timeline/film/cinema/:city', location.pathname)) {
      pathname = `/visualisation/timeline/film/cinema/${choice.key}`;
    }

    return (
      <Link
        className={'btn btn-sm ' + (city === choice.key ? 'btn-success' : 'btn-outline-success')}
        key={choice.key}
        to={{
          pathname: pathname,
          search: location.search,
        }}
      >
        {choice.name}
      </Link>
    );
  });
  return <div className="btn-group me-2 mb-3">{buttons}</div>;
};
export default CityChoices;
