import { useLocation } from 'react-router-dom';

import BaseContainer from './BaseContainer';
import useResults from '../../hooks/useResults';
import SearchMarkers from '../Marker/SearchMarkers';

const EcaContainer = () => {
  const apiPath = `${process.env.REACT_APP_API_URL}/map/cinema-search`;

  const { items, aggregations, isLoading, error } = useResults(apiPath);

  return (
    <BaseContainer
      showMarkerNav={true}
      showAggregations={true}
      aggregations={aggregations}
      isLoading={isLoading}
      markers={<SearchMarkers cinemas={items} />}
    />
  );
};

export default EcaContainer;
