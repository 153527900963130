import axios from 'axios';
import { Routes, Route } from 'react-router-dom';

import CinemaImageShow from './components/CinemaImageShow';
import CinemaList from './components/CinemaList';
import CinemaShow from './components/CinemaShow';
import CinemaView from './components/CinemaView';
import CityList from './components/CityList';
import CityShow from './components/CityShow';
import CompanyList from './components/CompanyList';
import CompanyShow from './components/CompanyShow';
import FilmList from './components/FilmList';
import FilmShow from './components/FilmShow';
import Homepage from './components/Homepage';
import InterviewList from './components/InterviewList';
import InterviewShow from './components/InterviewShow';
import Map from './components/Map';
import NotFound from './components/NotFound';
import PersonList from './components/PersonList';
import PersonShow from './components/PersonShow';
import PriceProxy from './components/PriceProxy';
import ScreeningList from './components/ScreeningList';
import ScreeningShow from './components/ScreeningShow';
import Search from './components/Search';
import TopCinema from './components/TopCinema';
import TopCinemaMap from './components/TopCinemaMap';
import TopFilm from './components/TopFilm';
import TopFilmCinema from './components/TopFilmCinema';
import TopFilmCinemaCompare from './components/TopFilmCinemaCompare';
import VisualisationCityActivity from './components/VisualisationCityActivity';
import VisualisationFilmCirculation from './components/VisualisationFilmCirculation';
import VisualisationInsight from './components/VisualisationInsight';
import VisualisationList from './components/VisualisationList';
import VisualisationRank from './components/VisualisationRank';
import VisualisationScreeningComparison from './components/VisualisationScreeningComparison';
import ScrollToTop from './hooks/ScrollToTop';
import Page from './layout/Page';
import Store from './Store';

const App = () => {
  const url = `${process.env.REACT_APP_API_URL}/user-type`;

  axios.get(url, { withCredentials: true }).then((res) => {
    if (url !== res.request.responseURL) {
      window.location.replace(res.request.responseURL);
    }
  });

  return (
    <Store>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Page />}>
          <Route index element={<Homepage />} />
          <Route path="cinema/">
            <Route path="" element={<CinemaList />} />
            <Route path=":slug/" element={<CinemaView />}>
              <Route path="" element={<CinemaShow />} />
              <Route path="image/:image" element={<CinemaImageShow />} />
            </Route>
          </Route>
          <Route path="screening/">
            <Route path="" element={<ScreeningList />} />
            <Route path=":slug/" element={<ScreeningShow />} />
          </Route>
          <Route path="film/">
            <Route index element={<FilmList />} />
            <Route path=":slug/" element={<FilmShow />} />
          </Route>
          <Route path="person/">
            <Route index element={<PersonList />} />
            <Route path=":slug/" element={<PersonShow />} />
          </Route>
          <Route path="company/">
            <Route index element={<CompanyList />} />
            <Route path=":slug/" element={<CompanyShow />} />
          </Route>
          <Route path="city/">
            <Route index element={<CityList />} />
            <Route path=":slug/" element={<CityShow />} />
          </Route>
          <Route path="map" element={<Map />} />
          <Route path="visualisation" element={<VisualisationList />} />

          <Route path="visualisation">
            <Route index element={<VisualisationList />} />
            <Route path="city/activity" element={<VisualisationCityActivity />} />
            <Route
              path="timeline/:city1/:city2/:type/:value1/:value2"
              element={<VisualisationScreeningComparison />}
            />
            <Route path="timeline/film/cinema/:city" element={<VisualisationFilmCirculation />} />
            <Route path="rank/:type/:timePeriod/:series" element={<VisualisationRank />} />
            <Route path="insight/:entity/:filter/:type" element={<VisualisationInsight />} />
          </Route>

          <Route path="search" element={<Search />} />
          <Route path="interview" element={<InterviewList />} />
          <Route path="interview/:slug" element={<InterviewShow />} />
          <Route path="top/film/cinema/compare/:city/:film" element={<TopFilmCinemaCompare />} />
          <Route path="top/film/:city/:period/:n/:rank">
            <Route index element={<TopFilm />} />
            <Route path="cinema" element={<TopFilmCinema />} />
          </Route>
          <Route path="top/cinema/:city/:period/:n">
            <Route index element={<TopCinema />} />
            <Route path="map" element={<TopCinemaMap />} />
          </Route>
          <Route path="price-proxy/:city" element={<PriceProxy />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Store>
  );
};

export default App;
