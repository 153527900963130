import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import KeyIcon from '../../map/Icon/KeyIcon';
import CityChoices from '../../navigation/CityChoices';
import { Context } from '../../Store';
import FilmCinemaTimeline from '../chart/FilmCinemaTimeline';

const VisualisationFilmCirculationDetails = ({ years }) => {
  const { city } = useParams();
  const [state] = useContext(Context);

  const currentCity = state.navigationCities.find((c) => c.key === city);
  const cityName = currentCity !== undefined ? currentCity.name : '';

  const ranksKey = state.navigationRanks.map((rank) => {
    return (
      <li key={rank.key} className="nav-item">
        <div style={{ display: 'inline-block', width: '30px', padding: '6px 5px' }}>
          <KeyIcon color={rank.color} size={'1x'} />
        </div>
        {rank.name}
      </li>
    );
  });

  const citiesKey = state.navigationCities.map((city) => {
    if (city.key !== 'all') {
      return (
        <li key={city.key} className="nav-item">
          <div style={{ display: 'inline-block', width: '30px', padding: '6px 5px' }}>
            <KeyIcon color={city.color} size={'1x'} />
          </div>
          {city.name}
        </li>
      );
    }
    return null;
  });

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-start">
        <h1 className="">Circulation of Top 20 Films Within {cityName}</h1>
        <CityChoices />
      </div>

      <div>
        {city === 'all' ? (
          <nav className="navbar d-flex justify-content-end align-items-center">
            <span className="navbar-brand mb-0 h1">Cities:</span>{' '}
            <ul className="nav me-1">{citiesKey}</ul>
          </nav>
        ) : (
          <nav className="navbar d-flex justify-content-end align-items-center">
            <span className="navbar-brand mb-0 h1">Ranks:</span>{' '}
            <ul className="nav me-1 ">{ranksKey}</ul>
          </nav>
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          {years.map((year) => {
            return (
              <div key={year.year}>
                <h2>{year.year}</h2>
                <div>
                  {year.films.map((film) => {
                    return (
                      <FilmCinemaTimeline
                        key={film.film.id}
                        film={film}
                        city={city}
                        cities={state.navigationCities}
                        ranks={state.navigationRanks}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VisualisationFilmCirculationDetails;
