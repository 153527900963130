import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import useFilters from '../hooks/useFilters';

const AggregationBucket = ({
  aggregationName,
  aggregationLabel,
  name,
  aggregationKey,
  count,
  // pathname,
  reverse,
  color,
  nestedTitle,
  entityName,
}) => {
  const [searchParams, remove] = useFilters(aggregationName, aggregationKey);
  // const aggregationLabelPlural = aggregationLabel + (aggregationLabel.slice(-1) === 's' ? '' : 's');
  const aggregationLabelPlural = aggregationLabel + (aggregationLabel.slice(-1) === 's' ? '' : '');
  const tooltipId = `${entityName}-${aggregationName}-${aggregationKey}`
    .replace(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase();
  return (
    <>
      {/* <li className="list-group-item"> */}
      <div className="col-12">
        <span
          className="me-2"
          style={{
            backgroundColor: color,
            width: '1em',
            height: '1em',
            display: 'inline-block',
          }}
        ></span>
        {/* {count === 0 ? (
          <span className={`text-break text-muted`}>
            {name} ({count})
          </span>
        ) : (
          <Link
            className={`text-break link-primary`}
            to={{
              search: searchParams.toString(),
            }}
          >
            {name} ({count}) {remove && <span>[x]</span>}
          </Link>
        )} */}

        {reverse && reverse !== count ? (
          <Link
            className={`text-break link-primary`}
            to={{
              search: searchParams.toString(),
            }}
          >
            <span
              data-tooltip-content={`${reverse.toLocaleString()} ${entityName} with ${nestedTitle} ${aggregationLabelPlural}: ${name} across ${count.toLocaleString()} screenings.`}
              data-tooltip-id={tooltipId}
              // data-tooltip-content="Hello world!"
            >
              {name} ({reverse.toLocaleString()}) [{count.toLocaleString()}]
              <Tooltip id={tooltipId} />
            </span>

            {remove && <span>[x]</span>}
          </Link>
        ) : (
          <Link
            className={`text-break link-primary`}
            to={{
              search: searchParams.toString(),
            }}
          >
            {name} ({count.toLocaleString()}) {remove && <span>[x]</span>}
          </Link>
        )}
      </div>
      {/* </li> */}
    </>
  );
};

AggregationBucket.propTypes = {
  aggregationName: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  pathname: PropTypes.string,
};

export default AggregationBucket;
