import { faFileExcel, faFileCsv, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const DownloadData = ({ entity, entityName, id, screeningField, screeningValue }) => {
  const dataPathCsv = `${process.env.REACT_APP_DOWNLOAD_URL}/${entity}/csv?id=${id}`;
  const dataPathXlsx = `${process.env.REACT_APP_DOWNLOAD_URL}/${entity}/xlsx?id=${id}`;
  const dataPathJson = `${process.env.REACT_APP_DOWNLOAD_URL}/${entity}/json?id=${id}`;

  const dataStatsPathCsv = `${process.env.REACT_APP_DOWNLOAD_URL}/aggregation/${entity}/${id}/csv`;
  const dataStatsPathXlsx = `${process.env.REACT_APP_DOWNLOAD_URL}/aggregation/${entity}/${id}/xlsx`;
  const dataStatsPathJson = `${process.env.REACT_APP_DOWNLOAD_URL}/aggregation/${entity}/${id}/json`;

  const dataScreeningPathCsv = `${process.env.REACT_APP_DOWNLOAD_URL}/screening/csv?${screeningField}=${screeningValue}`;
  const dataScreeningPathXlsx = `${process.env.REACT_APP_DOWNLOAD_URL}/screening/xlsx?${screeningField}=${screeningValue}`;
  const dataScreeningPathJson = `${process.env.REACT_APP_DOWNLOAD_URL}/screening/json?${screeningField}=${screeningValue}`;

  return (
    <div className="row my-4">
      <div className="col">
        <>
          <span className="me-2">Download core {entityName} data as:</span>
          <div className="btn-group ">
            <a className="btn btn-sm btn-primary border" href={dataPathCsv}>
              <FontAwesomeIcon icon={faFileCsv} className="me-2" />
              CSV
            </a>
            <a className="btn btn-sm btn-primary border" href={dataPathXlsx}>
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Excel
            </a>
            <a className="btn btn-sm btn-primary border" href={dataPathJson}>
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              JSON
            </a>
          </div>
        </>
      </div>
      <div className="col">
        <>
          <span className="me-2">Download screening statistic as:</span>
          <div className="btn-group ">
            <a className="btn btn-sm btn-primary border" href={dataStatsPathCsv}>
              <FontAwesomeIcon icon={faFileCsv} className="me-2" />
              CSV
            </a>
            <a className="btn btn-sm btn-primary border" href={dataStatsPathXlsx}>
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Excel
            </a>
            <a className="btn btn-sm btn-primary border" href={dataStatsPathJson}>
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              JSON
            </a>
          </div>
        </>
      </div>
      {screeningField && screeningValue && (
        <div className="col">
          <span className="me-2">Download screening data for {entityName} as:</span>
          <div className="btn-group ">
            <a className="btn btn-sm btn-primary border" href={dataScreeningPathCsv}>
              <FontAwesomeIcon icon={faFileCsv} className="me-2" />
              CSV
            </a>
            <a className="btn btn-sm btn-primary border" href={dataScreeningPathXlsx}>
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Excel
            </a>
            <a className="btn btn-sm btn-primary border" href={dataScreeningPathJson}>
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              JSON
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadData;
