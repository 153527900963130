import React, { Component } from 'react';

export default class CinemaRecordAggregation extends Component {
  render() {
    const { aggs } = this.props;
    let aggRows = aggs.time_period.buckets.map((bucket) => {
      const options = { year: 'numeric' };
      const fullDate = new Date(bucket.key_as_string);
      const displayDate = fullDate.toLocaleDateString('en-GB', options);
      if (bucket.venue.buckets.length > 0) {
        return (
          <tr key={bucket.key}>
            <th>{displayDate}</th>
            <td>{bucket.doc_count}</td>
            <td>{bucket.venue.buckets[0].active_days.value}</td>
            <td>{bucket.venue.buckets[0].unique_title.value}</td>
            <td>{bucket.venue.buckets[0].isWeekday.doc_count}</td>
            <td>{bucket.venue.buckets[0].isWeekend.doc_count}</td>
          </tr>
        );
      }
    });

    if (typeof aggs.venue.buckets[0] !== 'undefined') {
      const allTime = (
        <tr key={'all-time'}>
          <th>All Time*</th>
          <td>{aggs.venue.buckets[0].doc_count}</td>
          <td>{aggs.venue.buckets[0].active_days.value}</td>
          <td>{aggs.venue.buckets[0].unique_title.value}</td>
          <td>{aggs.venue.buckets[0].isWeekday.doc_count}</td>
          <td>{aggs.venue.buckets[0].isWeekend.doc_count}</td>
        </tr>
      );
      aggRows.push(allTime);
    }

    return (
      <>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Year</th>
              <th>Screenings</th>
              <th>Active Days</th>
              <th>Unique Titles</th>
              <th>Weekday</th>
              <th>Weekend</th>
            </tr>
          </thead>
          <tbody>{aggRows}</tbody>
        </table>
        <strong>
          * All time include dates at margin of the project, i.e the end of 1950 and the beginning
          of 1954
        </strong>
      </>
    );
  }
}
