import React, { useState, useEffect } from 'react';

// import TopCinemaMarkers from '../Marker/TopCinemaMarkers';
import BaseContainer from './BaseContainer';

const TopCinemaContainer = ({ markers }) => {
  const [markerType, setmarkerType] = useState('thumbnail');

  const handleMarkerType = (newmarkerType) => {
    setmarkerType(newmarkerType);
  };

  return (
    <BaseContainer
      showMarkerNav={true}
      handleMarkerType={handleMarkerType}
      markers={markers}
      mapHeight="clamp(300px,calc(100vh - 400px), calc(100vh - 400px))"
    />
  );
};

export default TopCinemaContainer;
