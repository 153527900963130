import { useState, useEffect } from 'react';

import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'bootstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, Link } from 'react-router-dom';

import { codeColor } from '../../utils/colors';

const Codes = ({ codes }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const myCollapse = document.getElementById('codes');
    const bsCollapse = new Collapse(myCollapse, { toggle: false });
    isOpen ? bsCollapse.show() : bsCollapse.hide();
  }, [isOpen]);

  return (
    <div className="card">
      <div
        className="card-header"
        type="button"
        aria-expanded="false"
        aria-controls="codes"
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        Codes{' '}
        {isOpen ? (
          <FontAwesomeIcon icon={faMinusSquare} />
        ) : (
          <FontAwesomeIcon icon={faPlusSquare} />
        )}
      </div>
      <div className="collapse" id="codes">
        <div className="card-body ">
          <ul className="list-inline">
            {codes.map(code => {
              const color = codeColor(code);
              const oppositeColor = 'white';
              const textColor = code.doc_count > 0 ? oppositeColor : color;
              const backgroundColor = code.doc_count > 0 ? oppositeColor : color;
              const borderColor = code.doc_count > 0 ? color : oppositeColor;

              let remove = false;
              const query = queryString.parse(location.search, {
                arrayFormat: 'bracket',
              });

              if (Object.hasOwnProperty.bind(query)('codes_name')) {
                if (query['codes_name'].includes(code.name)) {
                  remove = true;
                  query['codes_name'] = query['codes_name'].filter(item => item !== code.name);
                } else {
                  query['codes_name'].push(code.name);
                }
              } else {
                query.codes_name = [code.name];
              }
              const search = queryString.stringify(query, {
                arrayFormat: 'bracket',
              });

              const disabledClass = code.doc_count === 0 ? ' disabled' : '';
              const displayClass = code.doc_count === 0 ? ' d-none' : '';
              return (
                <li key={code.id} className={`list-inline-item${displayClass}`}>
                  <Link
                    className={`btn btn-sm mb-2${disabledClass}`}
                    style={{
                      color: textColor,
                      borderColor: backgroundColor,
                      backgroundColor: borderColor,
                    }}
                    title={code.fullName}
                    to={{
                      pathname: location.pathname,
                      search: search,
                      hash: location.hash,
                    }}
                    aria-disabled={code.doc_count === 0}
                  >
                    {code.name} ({code.doc_count}) {remove && <span>[x]</span>}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

Codes.propTypes = {
  codes: PropTypes.array,
};

export default Codes;
