import React, { useEffect, useContext } from 'react';

import axios from 'axios';
import { useLocation, Outlet } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header.jsx';
import Loading from './Loading';
import { Context } from '../Store';

const Page = () => {
  const location = useLocation();
  const [state, dispatch] = useContext(Context);
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/store`;
    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        const initialDatesString = res.data.initialDates;
        const initialDates = {
          dateFrom: new Date(initialDatesString.from),
          dateTo: new Date(initialDatesString.to),
        };
        // console.log(initialDatesString, initialDates);
        const data = { ...res.data, initialDates, isLoading: false };
        dispatch({ type: 'SET_DATA', payload: data });
      })
      .catch((error) => {
        dispatch({ type: 'SET_ERROR', payload: error });
      });
  }, [dispatch]);

  return (
    <>
      <Header />
      <main className="page-main container-fluid mt-4">
        {!state.isLoading && <Outlet />}
        {/* <Outlet /> */}
      </main>
      <Footer />
    </>
  );
};

export default Page;
