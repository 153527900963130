import { useState } from 'react';

import PropTypes from 'prop-types';

import AggregationBucket from './AggregationBucket';
import AggregationPieChart from './AggregationPieChart';
import COLORS from '../utils/colors';

const AggregationShow = ({ aggregation, nestedTitle, entityName }) => {
  const defaultSize = 8;
  const expandedSize = 20;
  let defaultLabel = 'show all';
  let size = defaultSize;
  let resizable = true;
  if (aggregation.cardinality < defaultSize) {
    size = aggregation.cardinality;
    resizable = false;
  } else if (aggregation.cardinality <= expandedSize) {
    defaultLabel = 'show all';
  }

  const [options, setOptions] = useState({
    defaultSize: defaultSize,
    expandedSize: expandedSize,
    resizable: resizable,
    resizeLabel: defaultLabel,
    size: size,
  });

  const handleResizeClick = () => {
    let newOptions = { ...options };

    switch (true) {
      case options.size === options.defaultSize:
        // &&
        // aggregation.cardinality <= options.expandedSize
        newOptions.size = aggregation.cardinality;
        newOptions.resizeLabel = 'show fewer';
        break;
      case options.size === aggregation.cardinality:
        // &&
        // aggregation.cardinality <= options.expandedSize
        newOptions.size = options.defaultSize;
        newOptions.resizeLabel = 'show all';
        break;
      // case options.size === options.defaultSize:
      //   // &&
      //   // aggregation.cardinality > options.expandedSize
      //   newOptions.size = options.expandedSize;
      //   newOptions.resizeLabel = "show all";
      //   break;
      // case options.size === options.expandedSize:
      //   // &&
      //   // aggregation.cardinality > options.expandedSize
      //   newOptions.size = aggregation.cardinality;
      //   newOptions.resizeLabel = "show fewer";
      //   break;
      // case options.size === aggregation.cardinality:
      //   // &&
      //   // aggregation.cardinality > options.expandedSize
      //   newOptions.size = options.defaultSize;
      //   newOptions.resizeLabel = "show more";
      //   break;
      default:
        newOptions.size = options.defaultSize;
        newOptions.resizeLabel = 'show all';
    }

    setOptions(newOptions);
  };

  if (aggregation.buckets.length === 0) {
    return null;
  }

  return (
    <>
      <div className="m-4 border-bottom">
        <div>
          <div className="">
            {/* <span className="">{aggregation.filterName}</span> */}
            <span className="">
              {aggregation.filterName} ({aggregation.cardinality.toLocaleString()})
            </span>
            <span>
              {options.resizable && (
                <button
                  className=" btn btn-sm btn-link float-end"
                  // style={{ color: 'white' }}
                  onClick={handleResizeClick}
                >
                  {options.resizeLabel}
                </button>
              )}
            </span>
          </div>
        </div>
        <div className="">
          <div className="">
            {/* <div className="row">
          <div className="d-none d-lg-block col-12 ms-4"> */}
            <div
              className="recharts-wrapper"
              style={{
                position: 'relative',
                cursor: 'default',
                width: '100px',
                height: '100px',
              }}
            >
              <AggregationPieChart buckets={aggregation.buckets} />
            </div>
          </div>

          <div className="">
            <div className="">
              <div className="" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                {aggregation.buckets.slice(0, options.size).map((value, index) => {
                  const color = COLORS[index % COLORS.length];
                  return (
                    <AggregationBucket
                      key={value.key}
                      aggregationKey={value.key}
                      aggregationName={aggregation.filterId}
                      aggregationLabel={aggregation.filterName}
                      name={value.name ? value.name : `${value.key}`}
                      count={value.doc_count}
                      reverse={value.reverse_doc_count}
                      color={color}
                      nestedTitle={nestedTitle}
                      entityName={entityName}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AggregationShow.propTypes = {
  aggregation: PropTypes.object,
  pathname: PropTypes.string,
};

export default AggregationShow;
