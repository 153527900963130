import { useContext } from 'react';

import { Link, useParams, useSearchParams } from 'react-router-dom';

import TopCinemaListItem from './TopCinemaListItem';
import { Context } from '../../Store';
import { formatTimePeriod } from '../../utils/dates';
import TopScreeningBar from '../chart/TopScreeningBar';

const TopCinemaPeriods = ({ periods }) => {
  const [searchParams] = useSearchParams();

  const [state, dispatch] = useContext(Context);
  const { city, period, n } = useParams();

  const currentSearchParams = new URLSearchParams(searchParams);

  const timePeriodListItems = periods.map((singlePeriod) => {
    const cinemaData = singlePeriod.venue.buckets.map((cinema) => ({
      Label: cinema.venueName.buckets[0].key,
      Screenings: cinema.doc_count,
    }));

    const formattedTimePeriod = formatTimePeriod(
      singlePeriod.key_as_string,
      period,
      state.initialDates.dateFrom,
      state.initialDates.dateTo,
    );

    return (
      <li key={singlePeriod.key_as_string}>
        <h2>
          {formattedTimePeriod.displayDate}{' '}
          <Link
            to={{
              pathname: `map`,
              search: currentSearchParams.toString(),
            }}
            className=""
          >
            View on map
          </Link>
        </h2>

        <div className="row">
          <div className="col-sm-6">
            <TopCinemaListItem cinemas={singlePeriod.venue.buckets} />
          </div>
          <div className="col-sm-6">
            <TopScreeningBar data={cinemaData} />
          </div>
        </div>
      </li>
    );
  });

  return <ul className="list-unstyled">{timePeriodListItems}</ul>;
};

export default TopCinemaPeriods;
