import { useSearchParams } from 'react-router-dom';

import useAxios from './useAxios';

const useInterviewResults = (apiPath) => {
  const [searchParams] = useSearchParams();

  const exclude = [];
  exclude.forEach((key) => {
    searchParams.delete(key);
  });
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath, searchParams.toString()]);
  // change to object to match the shape of the aggregations for the core types cinema, film, etc.
  let aggregations = {};
  if (data && data.aggregations) {
    data.aggregations.forEach((aggregation) => {
      aggregations[aggregation.key] = {
        buckets: aggregation.values,
        cardinality: aggregation.cardinality,
        filterId: aggregation.key,
        filterName: aggregation.name,
      };
    });
  }

  return {
    results: data.results ? data.results : [],
    aggregations: aggregations,
    codes: data.codes ? data.codes : [],
    nbResults: data.nbResults ? data.nbResults : 0,
    isLoading: isLoading,
    error: error,
  };
};

export default useInterviewResults;
