import { useParams } from 'react-router-dom';

import VisualisationFilmCirculationDetails from './visualisation/VisualisationFilmCirculationDetails';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const VisualisationFilmCirculation = () => {
  const { city } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Visualisations Film Circulation`;

  const apiPath = `${process.env.REACT_APP_API_URL}/city/${city}/20/priceproxy`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <VisualisationFilmCirculationDetails years={data.priceProxyFilmTimeline} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default VisualisationFilmCirculation;
