import { useParams, Link } from 'react-router-dom';

import SimpleBar from './chart/SimpleBar';
import SimplePie from './chart/SimplePie';
import SimpleTable from './chart/SimpleTable';
import useAxios from '../hooks/useAxios';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

const VisualisationInsight = () => {
  const { entity, filter, type } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Visualisations Insight`;

  const apiPath = `${process.env.REACT_APP_API_URL}/visualisation/simple/${entity}/${filter}`;
  const downloadPath = `${process.env.REACT_APP_API_URL}/visualisation/simple/${entity}/${filter}/csv`;

  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <div className="row mb-3">
      <div className="col-12">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <>
                <div className="d-flex flex-wrap justify-content-between align-items-start">
                  <h1>
                    {data.entityName} - {data.filterName}
                  </h1>

                  <div className="btn-group">
                    <Link
                      className={
                        'btn btn-sm ' + (type === 'bar' ? 'btn-primary' : 'btn-outline-primary')
                      }
                      to={{
                        pathname: `/visualisation/insight/${entity}/${filter}/bar`,
                      }}
                    >
                      Bar
                    </Link>
                    <Link
                      className={
                        'btn btn-sm ' + (type === 'pie' ? 'btn-primary' : 'btn-outline-primary')
                      }
                      to={{
                        pathname: `/visualisation/insight/${entity}/${filter}/pie`,
                      }}
                    >
                      Pie
                    </Link>
                  </div>
                </div>
                {type === 'bar' && (
                  <SimpleBar data={data.results.buckets} filterName={data.filterName} />
                )}
                {type === 'pie' && (
                  <div style={{ width: '100%', height: 500 }}>
                    <SimplePie data={data.results.buckets} filterName={data.filterName} />
                  </div>
                )}
                <SimpleTable data={data.resultsAll.buckets} filterName={data.filterName} />

                <a href={downloadPath} className="btn btn-primary">
                  Download CSV
                </a>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VisualisationInsight;
