import TopCinemaContainer from '../../map/Container/TopCinemaContainer';
import CinemaMarker from '../../map/Marker/CinemaMarker';
const TopCinemaMapDetails = ({ cinemas }) => {
  const markers = Object.values(cinemas).map((cinema) => {
    return <CinemaMarker key={cinema.id} cinema={cinema} />;
  });

  return (
    <>
      <div className="">
        <TopCinemaContainer markers={markers} />
      </div>
    </>
  );
};

export default TopCinemaMapDetails;
