import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';

// import { COLORS } from '../../utils/colors';
import PieChartIcon from '../Icon/PieChartIcon';
import CinemaPopup from '../Popup/CinemaPopup';

function TimelineChartMarker({ title, data }) {
  const iconAnchor = [25, 25];
  const iconSize = [50, 50];

  const icon = L.divIcon({
    iconAnchor: iconAnchor,
    iconSize: iconSize,
    html: ReactDOMServer.renderToString(<PieChartIcon data={data} animate={false} size={50} />),
  });

  const header = (
    <>
      <div className="d-flex justify-content-center ">
        <h6>{title}</h6>
      </div>

      <div className="d-flex justify-content-center ">
        <PieChartIcon data={data} />
      </div>
    </>
  );
  return (
    <Marker position={data.cinema.address.position} icon={icon} cinemaId={data.cinema.id}>
      <CinemaPopup cinema={data.cinema} header={header} />
    </Marker>
  );
}

export default TimelineChartMarker;
