import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CodeTags from './CodeTags';
import EntityTags from './EntityTags';
import { formatSeconds } from '../../utils/dates';

const EntityInterview = ({ transcriptLines }) => {
  if (transcriptLines.length === 0) {
    return null;
  }

  const groupedByInterviewId = transcriptLines.reduce((acc, line) => {
    const interviewId = line.interview.id;

    if (!acc[interviewId]) {
      acc[interviewId] = [];
    }

    acc[interviewId].push(line);

    return acc;
  }, {});

  return (
    <div className="row my-3">
      <div className="col-12">
        <h2>Mentions in Interviews:</h2>
        <ul className="list-unstyled">
          {Object.entries(groupedByInterviewId).map(([interviewId, lines]) => (
            <li key={interviewId}>
              <h3 className="fw-bold fs-4">
                <Link
                  to={{
                    pathname: `/interview/${interviewId}`,
                  }}
                >
                  {lines[0].interview.name}:
                </Link>
              </h3>
              {lines.map((transcriptLine) => (
                <div
                  key={transcriptLine.id}
                  className="d-flex flex-row border-top border-bottom py-2"
                >
                  <div className="col-6">
                    <Link
                      className="btn btn-sm btn-link text-start"
                      title={`${formatSeconds(transcriptLine.start)} -> ${formatSeconds(
                        transcriptLine.end,
                      )}`}
                      to={{
                        pathname: `/interview/${transcriptLine.interview.id}`,
                        search: transcriptLine.interview.isVideoPublic
                          ? `s=${transcriptLine.start}`
                          : null,
                      }}
                    >
                      <strong>{transcriptLine.speaker}: </strong>
                      {transcriptLine.content}
                    </Link>
                  </div>
                  <div className="col-6">
                    {transcriptLine.codes.length > 0 && (
                      <CodeTags codes={transcriptLine.codes} isLink={false} />
                    )}
                    {transcriptLine.codes.length > 0 && <EntityTags line={transcriptLine} />}
                  </div>
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

EntityInterview.propTypes = {
  transcriptLines: PropTypes.array,
};

export default EntityInterview;
