import CinemaImages from './CinemaImages';
import CinemaRecordAggregation from './CinemaRecordAggregation';
import CinemaRecordSummaryAggregation from './CinemaRecordSummaryAggregation';
import CinemaSummary from './CinemaSummary';
import CinemaContainer from '../../map/Container/CinemaContainer';
import EntityInterview from '../interview/EntityInterview';
import ScreeningAggregationChart from '../results/show/chart/ScreeningAggregationChart';
import ScreeningByCityChart from '../results/show/chart/ScreeningsByCityChart';
import DownloadData from '../results/show/DownloadData';
import MostPopular from '../results/show/MostPopular';

const CinemaDetail = ({
  cinema,
  cinemaAggregations,
  screenings,
  linkAggregations,
  aggregations,
  details,
}) => {
  return (
    <>
      <div className="row">
        <h1 className="col">
          {cinema.name}, {cinema.address.city.displayName}
        </h1>
      </div>

      {screenings.some((screening) =>
        Object.keys(screening).some((key) => key !== 'date' && key !== 'date_string'),
      ) ? (
        <>
          <MostPopular linkAggregations={linkAggregations} aggregations={aggregations} />
          <ScreeningByCityChart screenings={screenings} id={cinema.id} screeningField="venueId" />
        </>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col-lg-6">
          <CinemaSummary cinema={cinema} details={details} />
        </div>
        <div className="col-lg-6">
          <CinemaContainer cinema={cinema} />
        </div>
      </div>
      {cinema.images.length > 0 && (
        <>
          <div className="row mt-4">
            <h2 className="col">Gallery</h2>
          </div>
          <CinemaImages cinema={cinema} />
        </>
      )}

      {screenings.some((screening) =>
        Object.keys(screening).some((key) => key !== 'date' && key !== 'date_string'),
      ) ? (
        <>
          <div className="row">
            {linkAggregations
              .filter((aggregation) => aggregation.values.length > 0)
              .map((aggregation) => (
                <ScreeningAggregationChart key={aggregation.key} aggregation={aggregation} />
              ))}
            {aggregations
              .filter((aggregation) => aggregation.values.length > 0)
              .map((aggregation) => (
                <ScreeningAggregationChart key={aggregation.key} aggregation={aggregation} />
              ))}
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <h3>Screening Summary</h3>
              <CinemaRecordAggregation aggs={cinemaAggregations} />

              <CinemaRecordSummaryAggregation
                bucketName={'filmCoProductionCountry'}
                bucketTitle={'Combined Production Countries'}
                aggregation={cinemaAggregations.time_period.buckets}
              />
              <CinemaRecordSummaryAggregation
                bucketName={'filmProductionCountry'}
                bucketTitle={'Involved Production Country'}
                aggregation={cinemaAggregations.time_period.buckets}
              />
              <CinemaRecordSummaryAggregation
                bucketName={'filmYear'}
                bucketTitle={'Film Year'}
                aggregation={cinemaAggregations.time_period.buckets}
              />
              <CinemaRecordSummaryAggregation
                bucketName={'filmGenre'}
                bucketTitle={'Film Genre'}
                aggregation={cinemaAggregations.time_period.buckets}
              />
            </div>
          </div>
          {/* TODO add these */}
          {/* <div className="row">
        <div className="col-md-12">
          <h3>Price Proxy</h3>
          <PriceProxyTable
            n={n}
            ppYears={priceProxy.years}
            ppFilms={priceProxy.films}
            ppCinemas={priceProxy.cinemas}
            ppCinemaCounts={priceProxy.cinemaCounts}
          />
        </div>
      </div> */}
          {/* <div className="row">
        <div className="col-md-12">
          <h3>Number of top 20 films shown</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>1951</th>
                <th>1952</th>
                <th>1953</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{countTop20_1951}</td>
                <td>{countTop20_1952}</td>
                <td>{countTop20_1953}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <h2 className="mt-4 fs-5">No screenings recorded this cinema.</h2>
            </div>
          </div>
        </>
      )}

      <EntityInterview transcriptLines={cinema.transcriptLines} />

      <DownloadData
        entity="cinema"
        entityName="Cinema"
        id={cinema.id}
        screeningField="venueId"
        screeningValue={cinema.id}
      />
    </>
  );
};
export default CinemaDetail;
