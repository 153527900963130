import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import PriceProxyRecord from './priceProxy/PriceProxyRecord';
import CityChoices from '../navigation/CityChoices';
import { Context } from '../Store';

const PriceProxy = () => {
  const { city } = useParams();
  const [state, dispatch] = useContext(Context);

  const currentCity = state.navigationCities.find((c) => c.key === city);
  const cityName = currentCity !== undefined ? currentCity.name : '';
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Price Proxy: ${cityName}`;

  return (
    <>
      <div className="row mb-4">
        {cityName !== '' && <h1 className="col-12 text-center">Price Proxy for {cityName}</h1>}
        {state.navigationCities.length > 0 && cityName === '' && (
          <h1 className="col-12 text-center">Price Proxy - Error unknown city!</h1>
        )}
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <CityChoices />
        </div>
      </div>
      <div className="">{cityName !== '' && <PriceProxyRecord city={city} />}</div>
    </>
  );
};

export default PriceProxy;
