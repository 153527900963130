import { timeFormat } from 'd3-time-format';
import PropTypes from 'prop-types';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { COLORPAIRS } from '../../utils/colors';

const Timeline = ({ cityCompare, colorIndex, types }) => {
  const renderTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;
      const dateFormatString = '%B %Y';
      const formatTime = timeFormat(dateFormatString);
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
          }}
        >
          <p>{formatTime(data.month_key)}</p>
          <p>
            <span>Screenings </span>
            {data.value}
          </p>
        </div>
      );
    }

    return null;
  };

  const dateFormatString = '%b';

  const parseDomain = (types) => [
    0,
    Math.max(
      Math.max.apply(
        null,
        types[0].data.map((entry) => entry.value),
      ),
      Math.max.apply(
        null,
        types[1].data.map((entry) => entry.value),
      ),
    ),
  ];

  const domain = parseDomain(types);
  const range = [16, 225];

  const baseHeight = 60;

  return (
    <>
      {types.map((type, index) => {
        const showTick = (index === 1 && !cityCompare) || (index === 3 && cityCompare) ? 1 : 0;

        return (
          <ResponsiveContainer key={type.name} width="100%" height={baseHeight}>
            <ScatterChart
              width={800}
              margin={{
                top: 10,
                right: 50,
                bottom: 0,
                left: 0,
              }}
            >
              <XAxis
                type="number"
                dataKey="month_key"
                scale="time"
                tick={{ fontSize: showTick * 14 }}
                tickLine={{ transform: 'translate(0, -6)' }}
                tickFormatter={timeFormat(dateFormatString)}
              />
              <YAxis
                type="number"
                dataKey="index"
                name={type.name}
                height={10}
                width={200}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{ value: type.name, position: 'insideRight' }}
              />
              <ZAxis type="number" dataKey="value" domain={domain} range={range} />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                wrapperStyle={{ zIndex: 100 }}
                content={renderTooltip}
              />
              <Scatter data={type.data} fill={COLORPAIRS[colorIndex][index]} />
            </ScatterChart>
          </ResponsiveContainer>
        );
      })}
    </>
  );
};

Timeline.propTypes = {
  cityCompare: PropTypes.bool,
  colorIndex: PropTypes.number,
  types: PropTypes.array,
};

export default Timeline;
