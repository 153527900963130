import React from 'react';

import { TileLayer } from 'react-leaflet';

function CityTileLayer({ cities, opacity, tms, minZoom, maxZoom }) {
  return Object.values(cities).map((city) => {
    return (
      <TileLayer
        minZoom={minZoom}
        maxZoom={maxZoom}
        key={city.id}
        opacity={opacity}
        tms={tms}
        url={city.url}
        bounds={city.bounds}
        maxNativeZoom={city.maxNativeZoom}
        attribution={city.attribution}
      />
    );
  });
}

export default CityTileLayer;
