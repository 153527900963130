const Footer = () => {
  return (
    <footer className="page-footer pt-1 pb-1">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <a href="https://www.dhi.ac.uk/" className="link-light">
              © DHI
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
