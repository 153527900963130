import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';

const Pagination = ({ pagination }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handlePageClick = (event) => {
    searchParams.set('page', event.selected + 1);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <ReactPaginate
      previousLabel={'Previous'}
      nextLabel={'Next'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      breakLabel={'...'}
      breakClassName={'page-link'}
      pageCount={pagination.pageCount || 1}
      marginPagesDisplayed={1}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      initialPage={pagination.current ? pagination.current - 1 : 0}
      disableInitialCallback={true}
    />
  );
};
export default Pagination;
