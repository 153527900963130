import { useParams, Link } from 'react-router-dom';

import Rank from '../chart/Rank';

const VisualisationRankDetails = ({ chart, categories, title }) => {
  const { type, timePeriod, series } = useParams();

  const charts = chart.map((year) => {
    return (
      <div className="visualisation-rank" key={year.year}>
        <div className="d-flex justify-content-center ">
          <h2 className="">{timePeriod === 'all' ? 'All Years' : year.year}</h2>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Screenings</th>
              <th>Unique Titles</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{year.screenings.toLocaleString()}</td>
              <td>{year.titles.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <Rank
          series={series}
          ranks={year.ranks}
          screenings={year.screenings}
          titles={year.titles}
        />
      </div>
    );
  });

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-start">
        <h1>{title}</h1>
        <div className="btn-group">
          <Link
            className={
              'btn btn-sm ' + (timePeriod === '1y' ? 'btn-primary' : 'btn-outline-primary')
            }
            to={{
              pathname: `/visualisation/rank/${type}/1y/${series}`,
            }}
          >
            By Year
          </Link>
          <Link
            className={
              'btn btn-sm ' + (timePeriod === 'all' ? 'btn-primary' : 'btn-outline-primary')
            }
            to={{
              pathname: `/visualisation/rank/${type}/all/${series}`,
            }}
          >
            All Years
          </Link>
          <Link
            className={
              'btn btn-sm ' + (series === 'screenings' ? 'btn-secondary' : 'btn-outline-secondary')
            }
            to={{
              pathname: `/visualisation/rank/${type}/${timePeriod}/screenings`,
            }}
          >
            Chart Screenings
          </Link>
          <Link
            className={
              'btn btn-sm ' + (series === 'titles' ? 'btn-secondary' : 'btn-outline-secondary')
            }
            to={{
              pathname: `/visualisation/rank/${type}/${timePeriod}/titles`,
            }}
          >
            Chart Titles
          </Link>
        </div>
      </div>
      {charts}
    </>
  );
};

export default VisualisationRankDetails;
