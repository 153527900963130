import { useSearchParams, Link } from 'react-router-dom';

import ecaLogo from '../../images/eca-logo.svg';

const ItemRow = ({ columns, item, showImage = false }) => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') ? `?keyword=${searchParams.get('keyword')}` : '';
  const imageSize = 80;
  const imageClass = 'border border-primary p-1';
  const image =
    item.images && item.images.length > 0 ? (
      <img
        src={item.images[0].srcThumb}
        height={imageSize}
        className={imageClass}
        alt="Cinema"
        style={{ maxWidth: `${imageSize}px` }}
      />
    ) : (
      <img src={ecaLogo} width={imageSize} className={imageClass} alt="ECA logo" />
    );
  const fields = columns.map((column) => {
    // get value for nested properties
    let value = column.property.split('.').reduce((acc, val) => {
      return acc[val];
    }, item);

    if (column.property === 'date') {
      const date = new Date(value);
      value = `${date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })} (${date.toLocaleDateString('en-GB', {
        weekday: 'long',
      })})`;
    }
    const content = !value ? null : <span>{Array.isArray(value) ? value.join(', ') : value}</span>;
    let path = '';
    switch (column.show) {
      case 'screening':
        path = `/${column.show}/${item.id}?cinema=${item.venueId}&date=${item.date}`;
        break;

      case 'city':
        path = `/${column.show}/${item.displayName}`;
        break;

      case 'interview':
        path = `/${column.show}/${item.id}${keyword}`;
        break;

      default:
        path = `/${column.show}/${item.id}`;
        break;
    }
    return (
      <td key={`${item.id}-${column.key}`}>
        {column.show !== '' ? <Link to={path}> {content}</Link> : <>{content}</>}
      </td>
    );
  });

  return (
    <tr>
      {showImage && <td>{image}</td>}
      {fields}
    </tr>
  );
};
export default ItemRow;
