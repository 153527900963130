import React from 'react';

import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { ecaFill } from '../../utils/colors';

const SimpleBar = ({ data, filterName }) => {
  const style = {
    padding: 6,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
  };

  const SimpleBarTooltip = ({ active, payload, filterName }) => {
    if (active) {
      const value = payload && payload.length ? payload[0].payload : null;
      return (
        <div className="area-chart-tooltip" style={style}>
          <p>
            <strong>{filterName + ' : '}</strong>
            <span>{value ? value.key : ' -- '}</span> <em>{value ? value.doc_count : ' -- '}</em>
          </p>
        </div>
      );
    }
    return null;
  };

  const TiltedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        width={1200}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" interval={0} tick={<TiltedAxisTick />} height={200} />
        <YAxis />
        <Tooltip content={<SimpleBarTooltip filterName={filterName} />} />
        <Bar dataKey="doc_count" fill={ecaFill} />
      </BarChart>
    </ResponsiveContainer>
  );
};

SimpleBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.array,
  data: PropTypes.array,
  filterName: PropTypes.string,
};

export default SimpleBar;
