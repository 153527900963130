import { useState, useEffect, useMemo, useRef } from 'react';

import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Tab } from 'bootstrap';
import parse from 'html-react-parser';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';

import Aggregation from './interview/Aggregation/Card/Aggregation';
import Codes from './interview/Codes';
import CodeTags from './interview/CodeTags';
import EcaVideo from './interview/EcaVideo';
import EntityTags from './interview/EntityTags';
import InterviewMetaData from './interview/InterviewMetadata';
import Speakers from './interview/Speakers';
import useInterviewResult from '../hooks/useInterviewResult';
import useInterviewSearch from '../hooks/useInterviewSearch';
import Loading from '../layout/Loading';
import { formatSeconds } from '../utils/dates';

const InterviewShow = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const videoRef = useRef();

  const initialKeyword = searchParams.get('keyword') ? searchParams.get('keyword') : '';
  const initialExact = searchParams.get('exact') === 'true' ? true : false;

  const { slug } = useParams();
  const downloadURL = `${
    process.env.REACT_APP_API_URL
  }/interview/${slug}/search/download?${searchParams.toString()}`;

  const apiPath = `${process.env.REACT_APP_API_URL}/interview/${slug}`;

  const { interview, video, error, isLoading } = useInterviewResult(apiPath);

  const [searchForm, setSearchForm] = useState({ keyword: initialKeyword, exact: initialExact });
  const [search, setSearch] = useState({ keyword: initialKeyword, exact: initialExact });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Interviews: ${
      interview.name || ''
    }`;
  }, [interview]);

  const searchApiPath = `${process.env.REACT_APP_API_URL}/interview/${slug}/search`;
  const { searchResults, searchIsLoading, searchError } = useInterviewSearch(searchApiPath);

  let newTranscriptLines = [...interview.transcriptLines];

  searchResults.transcriptLineHits.forEach((transcriptLine) => {
    const nitIndex = newTranscriptLines.findIndex((tl) => tl.id === transcriptLine.id);
    newTranscriptLines[nitIndex] = { ...transcriptLine, active: true };
  });

  const activeResults = {
    transcriptLineHits: newTranscriptLines,
  };

  const updateLocation = () => {
    let newSearchParams = new URLSearchParams(searchParams);
    if (search.keyword === '') {
      newSearchParams.delete('keyword');
    } else {
      newSearchParams.set('keyword', search.keyword);
    }
    if (search.exact === false) {
      newSearchParams.delete('exact');
    } else {
      newSearchParams.set('exact', true);
    }
    setSearchParams(newSearchParams, { replace: true });
  };

  const handleChange = (event) => {
    const value = event.target.name === 'exact' ? event.target.checked : event.target.value;

    setSearchForm({
      ...searchForm,
      [event.target.name]: value,
    });
  };

  const handleSearchChange = (newSearchFrom) => {
    setSearch({ ...newSearchFrom });
  };

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 400), []);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateLocation();
  };

  useEffect(() => {
    debouncedSearchChange(searchForm);
  }, [searchForm, debouncedSearchChange]);

  useEffect(() => {
    updateLocation();
  }, [search]);

  const collarNotes = (
    <>
      <p>
        A white-collar worker is a salaried professional, typically referring to general office
        workers and management.
      </p>
      <p>
        A blue-collar worker is a member of the working class who performs manual labor and either
        earns an hourly wage or is paid piece rate for the amount of work done.
      </p>
      <p>
        A pink-collar worker is also a member of the working class who performs in the service
        industry. They work in positions such as waiters, retail clerks, salespersons, certain
        unlicensed assistive personnel, and many other positions involving relations with people.
      </p>
    </>
  );
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container-fluid">
          {error.messafe > 0 ? (
            <div className="row">
              <div className="col">
                <ul className="list-unstyled">
                  {error.map((error) => (
                    <li className="text-danger" key={error}>
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <h1 className="col">
                  <Link to="">{interview.name}</Link>,{' '}
                  <Link to={`/city/${interview.city.displayName}`}>
                    {interview.city.displayName}
                  </Link>
                </h1>
              </div>

              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 interview-wrapper">
                  {interview.isVideoPublic ? (
                    <EcaVideo video={video} interview={interview} ref={videoRef} />
                  ) : (
                    <img
                      className="ratio ratio-16x9"
                      src={video.thumbnailSrc}
                      alt="video thumbnail"
                    />
                  )}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 interview-wrapper mt-2">
                  <InterviewMetaData value={interview.gender} label="Gender" />
                  <InterviewMetaData value={interview.birthYear} label="Birth Year" />
                  <InterviewMetaData value={interview.ageGroup} label="Age in 1950s" />
                  <InterviewMetaData value={interview.demographyAge} label="Age Category" />
                  <InterviewMetaData value={interview.residence} label="Residence" />
                  <InterviewMetaData value={interview.education} label="Education" />
                  <InterviewMetaData value={interview.occupationFather} label="Occupation Father" />
                  <InterviewMetaData
                    value={interview.demographyEducationFather}
                    label="Occupation Father Category"
                    help={collarNotes}
                  />
                  <InterviewMetaData value={interview.occupationMother} label="Occupation Mother" />
                  <InterviewMetaData
                    value={interview.demographyEducationMother}
                    label="Occupation Mother Category"
                    help={collarNotes}
                  />
                  <InterviewMetaData
                    value={interview.demographyFamilyComposition}
                    label="Family Composition"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3 mt-3">
                      <input
                        type="search"
                        name="keyword"
                        value={searchForm.keyword}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="keyword…"
                        aria-label="Keyword"
                        aria-describedby="basic-addon1"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <input
                            type="checkbox"
                            name="exact"
                            defaultChecked={searchForm.exact}
                            onChange={handleChange}
                            aria-label="Checkbox to enable exact phrase search for previous search input"
                            className="form-check-input ms-0"
                            id="checkbox-exact"
                          />
                          <label className="form-check-label ms-3" htmlFor="checkbox-exact">
                            Exact
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {searchIsLoading ? (
                <Loading />
              ) : (
                <>
                  {interview.isCoded && searchResults.aggregations.length > 0 && (
                    <>
                      <div className="row">
                        <div className="col-9">
                          <Codes codes={searchResults.codes} />
                        </div>
                        <div className="col-3">
                          <Speakers
                            speakers={searchResults.aggregations.find(
                              (aggregation) => aggregation.key === 'speaker',
                            )}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-3">
                          <Aggregation
                            aggregation={searchResults.aggregations.find(
                              (aggregation) => aggregation.key === 'cinemas',
                            )}
                          />
                        </div>
                        <div className="col-3">
                          <Aggregation
                            aggregation={searchResults.aggregations.find(
                              (aggregation) => aggregation.key === 'films',
                            )}
                          />
                        </div>
                        <div className="col-3">
                          <Aggregation
                            aggregation={searchResults.aggregations.find(
                              (aggregation) => aggregation.key === 'people',
                            )}
                          />
                        </div>
                        <div className="col-3">
                          <Aggregation
                            aggregation={searchResults.aggregations.find(
                              (aggregation) => aggregation.key === 'companies',
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="row mt-2">
                <div className="col-12">
                  <ul className="nav nav-tabs pb-2" id="myTab" role="tablist">
                    {interview.isCoded && (
                      <li className="nav-item" role="presentation">
                        <a
                          className={interview.isCoded ? 'nav-link active' : 'nav-link'}
                          id="coded-transcript-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#coded-transcript"
                          type="button"
                          role="tab"
                          aria-controls="coded-transcript"
                          aria-selected="true"
                        >
                          Coded Transcript{' '}
                          <span className="badge bg-danger">
                            {searchResults.transcriptLineHits.length}
                          </span>
                        </a>
                      </li>
                    )}

                    {interview.fullTranscripts.map((transcript) => {
                      let count = 0;
                      if (searchResults.highlights.length > 0) {
                        const highlight = searchResults.highlights.find(
                          (highlight) => highlight.language === transcript.language,
                        );
                        count = highlight.count;
                      }

                      return (
                        <li className="nav-item" role="presentation" key={transcript.language}>
                          <a
                            className="nav-link"
                            id={`${transcript.language}-transcript-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${transcript.language}-transcript`}
                            type="button"
                            role="tab"
                            aria-controls={transcript.language}
                            aria-selected="false"
                          >
                            {transcript.title} <span className="badge bg-danger">{count}</span>
                          </a>
                        </li>
                      );
                    })}
                    <li className="ms-auto">
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        type="button"
                        onClick={() => setIsOpen((isOpen) => !isOpen)}
                      >
                        {isOpen ? (
                          <>
                            View only lines matching search <FontAwesomeIcon icon={faMinusSquare} />
                          </>
                        ) : (
                          <>
                            View all lines <FontAwesomeIcon icon={faPlusSquare} />
                          </>
                        )}
                      </button>
                    </li>
                    <li>
                      {' '}
                      <a href={downloadURL} className="ms-2 btn btn-sm btn-primary">
                        Download Coded Transcript
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    {interview.isCoded && (
                      <div
                        className={
                          interview.isCoded ? 'tab-pane fade show active' : 'tab-pane fade'
                        }
                        id="coded-transcript"
                        role="tabpanel"
                        aria-labelledby="coded-transcript-tab"
                      >
                        {searchIsLoading ? (
                          <Loading />
                        ) : (
                          <ul className="list-unstyled">
                            {activeResults.transcriptLineHits.map((line) => {
                              if (!line.hasOwnProperty('active') && !isOpen) {
                                return null;
                              }

                              return (
                                <li
                                  key={line.id}
                                  className={`${
                                    line.hasOwnProperty('active')
                                      ? ' interview-line-hit'
                                      : ' interview-line-miss'
                                  }`}
                                >
                                  <div className={`d-flex flex-row border-top border-bottom`}>
                                    <div className="col-6">
                                      {interview.isVideoPublic ? (
                                        <button
                                          className="btn btn-sm btn-link text-start button-select"
                                          onClick={() => videoRef.current.seek(line.start)}
                                          title={`${formatSeconds(line.start)} -> ${formatSeconds(
                                            line.end,
                                          )}`}
                                        >
                                          <strong>{line.speaker}: </strong>
                                          <div className="d-inline-block">
                                            {parse(line.content)}
                                          </div>
                                        </button>
                                      ) : (
                                        <>
                                          <strong>{line.speaker}: </strong>
                                          <div className="d-inline-block">
                                            {parse(line.content)}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="col-6">
                                      {line.codes.length > 0 && <CodeTags codes={line.codes} />}
                                      {line.codes.length > 0 && <EntityTags line={line} />}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    )}
                    {interview.fullTranscripts.map((transcript) => {
                      const highlight = searchResults.highlights.find(
                        (highlight) => highlight.language === transcript.language,
                      );
                      const content =
                        highlight?.highlights.length > 0
                          ? highlight.highlights[0]
                          : transcript.content;
                      return (
                        <div
                          className="tab-pane fade"
                          id={`${transcript.language}-transcript`}
                          role="tabpanel"
                          aria-labelledby={`${transcript.language}-transcript-tab`}
                          key={transcript.language}
                        >
                          <div key={transcript.language} className="row">
                            <div className="col-12 ps-5 pe-5 pt-2">
                              <div style={{ whiteSpace: 'pre-wrap' }}>{parse(content)}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default InterviewShow;
